import { useEffect } from 'react'
import HeaderMenu from './HeaderMenu'
import { useLocation, useLosseLayout } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { useCustomLayout } from '~/hooks/useCustomLayout'

export default function HeaderDesktop() {
  const { isSticky } = useLosseLayout()
  const { desktopMenuOpen, setDesktopMenuOpen } = useCustomLayout()
  const location = useLocation()

  useEffect(() => {
    if (desktopMenuOpen) {
      setDesktopMenuOpen(false)
    }
    // eslint-disable-next-line
  }, [location.pathname, location.hash, location.search])

  return (
    <ul className={clsx(!isSticky && 'lg:bg-white lg:px-8 xl:px-14', 'rounded-full items-center justify-end flex smooth py-5')}>
      {isSticky ? (
        <div className="fixed bg-white rounded-full drop-shadow lg:-mt-10" id="hamburgerMenu">
          <div onClick={() => setDesktopMenuOpen(true)} className="flex cursor-pointer gap-[10px] items-center px-6 lg:px-14 py-2 lg:py-4">
            <div className="flex flex-col w-6 gap-1">
              <div className="h-[3px] bg-site-nav w-full rounded-full" />
              <div className="h-[3px] bg-site-nav w-full rounded-full" />
              <div className="h-[3px] bg-site-nav w-full rounded-full" />
            </div>
            <div className="text-site-nav font-bold text-base">Menu</div>
          </div>
        </div>
      ) : (
        <>
          <div className="lg:block hidden">
            <HeaderMenu />
          </div>
          <div className="fixed lg:hidden block bg-white px-6 rounded-full py-2" id="hamburgerMenu">
            <div onClick={() => setDesktopMenuOpen(true)} className="flex cursor-pointer gap-[10px] items-center">
              <div className="flex flex-col w-6 gap-1">
                <div className="h-[3px] bg-site-nav w-full rounded-full" />
                <div className="h-[3px] bg-site-nav w-full rounded-full" />
                <div className="h-[3px] bg-site-nav w-full rounded-full" />
              </div>
              <div className="text-site-nav font-bold text-base">Menu</div>
            </div>
          </div>
        </>
      )}
    </ul>
  )
}
