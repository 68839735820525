import { type LoaderData, useLosseLayout } from '@ubo/losse-sjedel'
import { LosseLink } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import type { Component_Header } from '~/graphql/types'
import HeaderDesktop from './HeaderDesktop'
import SubHeaderDesktop from './SubHeaderDesktop'
import { useCustomLayout } from '~/hooks/useCustomLayout'
import SubMenu from './SubHeaderMenu'
import HeaderMenu from './HeaderMenu'

export interface HeaderLoaderData extends Omit<LoaderData, 'header'> {
  header: Component_Header
}

export default function Header() {
  const { setScrollable, isSticky } = useLosseLayout()
  const { desktopMenuOpen, setDesktopMenuOpen } = useCustomLayout()

  return (
    <nav
      data-component="Header"
      className={clsx(isSticky ? 'pt-5 lg:pt-0' : 'pt-0', 'absolute left-0 top-0 right-0 h-[70px] lg:h-[140px] z-50 transition-all')}
    >
      <div className="container flex flex-col h-full items-end">
        <div>
          <SubHeaderDesktop />
        </div>
        <div className="flex relative items-center justify-between w-full">
          <LosseLink to="/" aria-label="Home">
            <svg
              className="w-[180px] lg:w-[200px] xl:w-[319px]"
              width="319"
              height="60"
              viewBox="0 0 319 60"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_662_5200)">
                <path
                  d="M17.7229 36.0938L6.13534 22.0439H0V48.4206H7.26121V34.3645L18.8931 48.4206H25.0221V22.0439H17.7229V36.0938Z"
                  fill="#2F6F7D"
                />
                <path d="M68.1846 27.9603H76.2744V48.4206H83.7317V27.9603H91.7835V22.0439H68.1846V27.9603Z" fill="#2F6F7D" />
                <path
                  d="M103.599 22.0439L91.9733 48.427H99.576L101.644 43.2644H112.783L114.851 48.427H122.606L110.942 22.0439H103.605H103.599ZM103.833 37.7977L107.21 29.3602L110.588 37.7977H103.826H103.833Z"
                  fill="#2F6F7D"
                />
                <path
                  d="M148.924 36.9679C149.867 35.5363 150.335 33.8007 150.335 31.7673C150.335 29.734 149.867 28.0553 148.924 26.5858C147.982 25.1162 146.635 23.995 144.876 23.2095C143.118 22.4303 141.037 22.0439 138.627 22.0439H126.622V48.427H134.073V41.4147H138.102L142.878 48.427H150.854L145.167 40.1288C146.78 39.3687 148.033 38.3235 148.918 36.9743L148.924 36.9679ZM141.682 34.6305C140.904 35.3083 139.74 35.6504 138.184 35.6504H134.079V27.9223H138.184C139.74 27.9223 140.904 28.2707 141.682 28.9612C142.46 29.6516 142.846 30.5891 142.846 31.7673C142.846 32.9456 142.454 33.9527 141.682 34.6305Z"
                  fill="#2F6F7D"
                />
                <path d="M163.807 22.0439H156.356V48.427H163.807V22.0439Z" fill="#2F6F7D" />
                <path
                  d="M188.924 34.7635C188.033 34.1998 187.058 33.7437 186.008 33.408C184.952 33.0659 183.896 32.7872 182.827 32.5591C181.758 32.3311 180.79 32.1157 179.911 31.9004C179.032 31.685 178.317 31.4126 177.767 31.0705C177.217 30.7285 176.938 30.2597 176.938 29.658C176.938 29.2589 177.077 28.8788 177.349 28.5304C177.628 28.1757 178.077 27.8906 178.703 27.6626C179.329 27.4346 180.158 27.3205 181.189 27.3205C182.34 27.3205 183.535 27.4852 184.762 27.8083C185.99 28.1377 187.236 28.6508 188.488 29.3539L190.746 23.8493C189.468 23.0701 187.995 22.4873 186.325 22.0946C184.655 21.7082 182.96 21.5118 181.227 21.5118C178.64 21.5118 176.483 21.9046 174.756 22.6774C173.023 23.4565 171.733 24.489 170.879 25.7686C170.025 27.0482 169.601 28.4671 169.601 30.0254C169.601 31.4316 169.873 32.6035 170.411 33.5283C170.948 34.4595 171.657 35.2133 172.536 35.7897C173.415 36.3661 174.389 36.8349 175.471 37.1833C176.546 37.538 177.609 37.8167 178.652 38.0321C179.696 38.2475 180.657 38.4692 181.549 38.7099C182.441 38.9506 183.162 39.2357 183.712 39.5777C184.263 39.9198 184.541 40.3759 184.541 40.9523C184.541 41.402 184.396 41.7948 184.111 42.1178C183.82 42.4472 183.371 42.6943 182.757 42.8716C182.144 43.049 181.309 43.1377 180.253 43.1377C178.722 43.1377 177.185 42.8906 175.642 42.4029C174.098 41.9151 172.738 41.2944 171.556 40.5406L169.108 46.0832C170.335 46.913 171.948 47.5971 173.947 48.1356C175.939 48.674 178.033 48.9464 180.215 48.9464C182.821 48.9464 184.996 48.5536 186.723 47.7808C188.456 47.0017 189.753 45.9692 190.619 44.6706C191.486 43.3784 191.916 41.9658 191.916 40.4329C191.916 39.052 191.638 37.8991 191.088 36.9869C190.537 36.0684 189.816 35.3273 188.924 34.7635Z"
                  fill="#2F6F7D"
                />
                <path
                  d="M222.928 22.0439H214.762L204.56 33.0026V22.0439H197.223V48.4206H204.56V41.8138L207.33 38.8493L214.876 48.4206H223.529L212.226 33.731L222.928 22.0439Z"
                  fill="#2F6F7D"
                />
                <path d="M234.857 22.0439H227.406V48.4206H247.387V42.5042H234.857V22.0439Z" fill="#2F6F7D" />
                <path
                  d="M269.101 36.4738C269.101 38.7099 268.671 40.3188 267.805 41.3007C266.938 42.2825 265.73 42.7703 264.174 42.7703C262.618 42.7703 261.404 42.2825 260.524 41.3007C259.645 40.3188 259.209 38.7162 259.209 36.4738V22.0376H251.758V36.6955C251.758 40.5912 252.846 43.6064 255.015 45.7411C257.185 47.8759 260.215 48.9464 264.104 48.9464C267.994 48.9464 270.999 47.8759 273.194 45.7411C275.388 43.6064 276.489 40.5912 276.489 36.6955V22.0376H269.114V36.4738H269.101Z"
                  fill="#2F6F7D"
                />
                <path d="M290.891 22.0439H283.44V48.427H290.891V22.0439Z" fill="#2F6F7D" />
                <path
                  d="M318.171 36.9869C317.621 36.0684 316.9 35.3273 316.008 34.7635C315.116 34.1998 314.142 33.7437 313.092 33.408C312.036 33.0659 310.98 32.7872 309.911 32.5591C308.842 32.3311 307.874 32.1157 306.995 31.9004C306.116 31.685 305.401 31.4126 304.851 31.0705C304.3 30.7285 304.022 30.2597 304.022 29.658C304.022 29.2589 304.161 28.8788 304.433 28.5304C304.712 28.1757 305.161 27.8906 305.787 27.6626C306.413 27.4346 307.242 27.3205 308.273 27.3205C309.424 27.3205 310.619 27.4852 311.846 27.8083C313.073 28.1377 314.319 28.6508 315.572 29.3539L317.83 23.8493C316.552 23.0701 315.078 22.4873 313.409 22.0946C311.739 21.7082 310.044 21.5118 308.311 21.5118C305.724 21.5118 303.567 21.9046 301.84 22.6774C300.107 23.4565 298.817 24.489 297.963 25.7686C297.109 27.0482 296.685 28.4671 296.685 30.0254C296.685 31.4316 296.957 32.6035 297.495 33.5283C298.032 34.4595 298.741 35.2133 299.62 35.7897C300.499 36.3661 301.473 36.8349 302.555 37.1833C303.63 37.538 304.693 37.8167 305.736 38.0321C306.78 38.2475 307.741 38.4692 308.633 38.7099C309.525 38.9506 310.246 39.2357 310.796 39.5777C311.347 39.9198 311.625 40.3759 311.625 40.9523C311.625 41.402 311.479 41.7948 311.195 42.1178C310.904 42.4472 310.455 42.6943 309.841 42.8716C309.228 43.049 308.393 43.1377 307.337 43.1377C305.806 43.1377 304.269 42.8906 302.726 42.4029C301.182 41.9151 299.822 41.2944 298.64 40.5406L296.192 46.0832C297.419 46.913 299.032 47.5971 301.03 48.1356C303.023 48.674 305.116 48.9464 307.299 48.9464C309.905 48.9464 312.08 48.5536 313.807 47.7808C315.54 47.0017 316.837 45.9692 317.703 44.6706C318.57 43.3784 319 41.9658 319 40.4329C319 39.052 318.722 37.8991 318.171 36.9869Z"
                  fill="#2F6F7D"
                />
                <path
                  d="M47.7861 21.0431C40.721 21.0431 34.9715 27.4282 34.9715 35.283C34.9715 43.1377 40.721 49.5228 47.7861 49.5228C54.8513 49.5228 60.6008 43.1377 60.6008 35.283C60.6008 27.4282 54.8513 21.0431 47.7861 21.0431ZM57.0524 28.5558C57.4509 29.1132 57.3244 29.886 56.7678 30.2914L53.3016 32.7808C53.5356 33.56 53.6558 34.4025 53.6558 35.283C53.6558 36.1634 53.5356 36.9426 53.3206 37.6964L56.7614 40.1668C57.318 40.5659 57.4445 41.345 57.0461 41.9025C56.8057 42.2382 56.4262 42.4219 56.0404 42.4219C55.7874 42.4219 55.5407 42.3459 55.3193 42.1875L52.2643 39.9895C51.5748 40.9966 50.664 41.7758 49.633 42.2002V46.0452C49.633 46.7293 49.0764 47.2868 48.3933 47.2868C47.7102 47.2868 47.1536 46.7293 47.1536 46.0452V42.5232C45.6103 42.3142 44.2441 41.364 43.2953 39.9641L40.2086 42.1812C39.9873 42.3395 39.7343 42.4156 39.4876 42.4156C39.1018 42.4156 38.7222 42.2382 38.4819 41.8961C38.0834 41.3387 38.2099 40.5659 38.7665 40.1605L42.2453 37.6584C42.0366 36.9109 41.9227 36.1064 41.9227 35.2766C41.9227 34.4468 42.0493 33.5726 42.2706 32.7998L38.7665 30.2851C38.2099 29.886 38.0834 29.1069 38.4819 28.5494C38.8804 27.992 39.6584 27.8653 40.215 28.2644L43.3585 30.5194C44.3073 29.1575 45.6482 28.239 47.1663 28.03V24.508C47.1663 23.8239 47.7229 23.2665 48.406 23.2665C49.0891 23.2665 49.6457 23.8239 49.6457 24.508V28.3531C50.6577 28.7711 51.5432 29.5186 52.2263 30.4878L55.332 28.258C55.8886 27.859 56.6602 27.9857 57.065 28.5431L57.0524 28.5558Z"
                  fill="#2F6F7D"
                />
                <path
                  d="M65.4331 23.3741H64.0353V17.9645C64.0353 15.9501 62.6375 14.2145 60.7779 13.9231L35.6293 10.0591C34.5983 9.90072 33.5357 10.2111 32.7261 10.9079C31.8216 11.687 31.3029 12.8462 31.3029 14.0941V55.9142C31.3029 57.1558 31.8153 58.3087 32.7134 59.0878C33.3965 59.6832 34.2251 59.9936 35.0853 59.9936C35.2561 59.9936 35.4269 59.981 35.5977 59.9556L60.7463 56.3703C62.6248 56.1043 64.0416 54.3623 64.0416 52.3289V49.7888H65.4395C66.1226 49.7888 66.6792 49.2314 66.6792 48.5473V43.5367C66.6792 42.8526 66.1226 42.2952 65.4395 42.2952H64.0416V30.8615H65.4395C66.1226 30.8615 66.6792 30.304 66.6792 29.6199V24.6093C66.6792 23.9252 66.1226 23.3678 65.4395 23.3678L65.4331 23.3741ZM61.5559 52.3353C61.5559 53.1461 61.0562 53.8239 60.392 53.9189L35.2435 57.5042C34.8323 57.5675 34.5224 57.3838 34.3326 57.2191C33.9784 56.9151 33.776 56.44 33.776 55.9206V14.1005C33.776 13.581 33.9784 13.106 34.339 12.7956C34.5034 12.6562 34.7564 12.5042 35.0853 12.5042C35.1423 12.5042 35.1929 12.5042 35.2561 12.5169L60.4047 16.3809C61.0625 16.4822 61.5559 17.16 61.5559 17.9645V52.3289V52.3353Z"
                  fill="#2F6F7D"
                />
                <path
                  d="M30.3984 52.614V52.5697C30.1581 52.6014 29.981 52.4937 29.8671 52.3987C29.6394 52.2023 29.5066 51.8792 29.5066 51.5372V17.9772C29.5066 17.6351 29.6457 17.3121 29.8734 17.1157C29.9873 17.0207 30.1644 16.913 30.3984 16.951V14.4616C29.6268 14.4362 28.8614 14.7086 28.2605 15.2281C27.4762 15.8995 27.0335 16.9003 27.0335 17.9772V51.5372C27.0335 52.6077 27.4762 53.6022 28.2479 54.2737C28.8298 54.7804 29.5698 55.0528 30.3162 55.0528C30.3478 55.0528 30.3731 55.0528 30.4048 55.0528V52.614H30.3984Z"
                  fill="#2F6F7D"
                />
                <path
                  d="M126.628 16.7293V0.943848H132.865C134.529 0.943848 135.996 1.27957 137.255 1.95736C138.513 2.63515 139.494 3.56631 140.189 4.75086C140.885 5.9354 141.239 7.29731 141.239 8.83025C141.239 10.3632 140.892 11.7251 140.189 12.9096C139.494 14.0942 138.513 15.0317 137.255 15.7031C135.996 16.3809 134.529 16.7167 132.865 16.7167H126.628V16.7293ZM127.78 15.6905H132.776C134.263 15.6905 135.553 15.3991 136.647 14.81C137.742 14.2209 138.589 13.4164 139.19 12.3839C139.791 11.3577 140.088 10.1732 140.088 8.83025C140.088 7.48734 139.791 6.30913 139.19 5.27662C138.589 4.25044 137.742 3.43962 136.647 2.85052C135.553 2.26142 134.263 1.97003 132.776 1.97003H127.78V15.6778V15.6905Z"
                  fill="#171E39"
                />
                <path
                  d="M150.759 16.818C149.576 16.818 148.526 16.5646 147.621 16.0515C146.711 15.5384 146.002 14.8353 145.484 13.9295C144.965 13.03 144.706 11.9975 144.706 10.8383C144.706 9.67906 144.952 8.63387 145.439 7.74071C145.926 6.84755 146.597 6.14443 147.457 5.63134C148.311 5.11825 149.272 4.86487 150.341 4.86487C151.41 4.86487 152.365 5.11191 153.213 5.606C154.06 6.10009 154.731 6.79688 155.218 7.69004C155.705 8.5832 155.952 9.61571 155.952 10.7876C155.952 10.8319 155.952 10.8826 155.939 10.9333C155.933 10.984 155.926 11.041 155.926 11.1043H145.547V10.2238H155.319L154.87 10.6546C154.883 9.73607 154.693 8.91259 154.294 8.18413C153.896 7.45566 153.358 6.88556 152.688 6.47382C152.011 6.06208 151.233 5.85304 150.348 5.85304C149.462 5.85304 148.703 6.06208 148.02 6.47382C147.337 6.88556 146.799 7.45566 146.413 8.18413C146.021 8.91259 145.825 9.7424 145.825 10.6736V10.8763C145.825 11.8391 146.04 12.6879 146.464 13.4354C146.888 14.1829 147.482 14.7593 148.229 15.171C148.981 15.5828 149.835 15.7918 150.797 15.7918C151.549 15.7918 152.251 15.6588 152.903 15.3864C153.554 15.114 154.105 14.7023 154.56 14.1449L155.212 14.886C154.699 15.5194 154.06 15.9945 153.289 16.3176C152.517 16.6406 151.682 16.8053 150.778 16.8053L150.759 16.818Z"
                  fill="#171E39"
                />
                <path
                  d="M167.824 16.7293V0.943848H168.792L180.025 15.3041H179.506V0.943848H180.651V16.723H179.709L168.456 2.36277H168.975V16.723H167.824V16.7293Z"
                  fill="#171E39"
                />
                <path
                  d="M191.682 16.818C190.499 16.818 189.449 16.5646 188.545 16.0515C187.634 15.5384 186.926 14.8353 186.407 13.9295C185.888 13.03 185.629 11.9975 185.629 10.8383C185.629 9.67906 185.876 8.63387 186.363 7.74071C186.85 6.84755 187.52 6.14443 188.38 5.63134C189.234 5.11825 190.196 4.86487 191.265 4.86487C192.334 4.86487 193.289 5.11191 194.136 5.606C194.984 6.10009 195.654 6.79688 196.141 7.69004C196.628 8.5832 196.875 9.61571 196.875 10.7876C196.875 10.8319 196.875 10.8826 196.862 10.9333C196.856 10.984 196.85 11.041 196.85 11.1043H186.47V10.2238H196.242L195.793 10.6546C195.806 9.73607 195.616 8.91259 195.218 8.18413C194.819 7.45566 194.282 6.88556 193.611 6.47382C192.934 6.06208 192.156 5.85304 191.271 5.85304C190.385 5.85304 189.626 6.06208 188.943 6.47382C188.26 6.88556 187.723 7.45566 187.337 8.18413C186.945 8.91259 186.749 9.7424 186.749 10.6736V10.8763C186.749 11.8391 186.964 12.6879 187.387 13.4354C187.817 14.1765 188.406 14.7593 189.152 15.171C189.905 15.5828 190.759 15.7918 191.72 15.7918C192.473 15.7918 193.175 15.6588 193.826 15.3864C194.478 15.114 195.028 14.7023 195.483 14.1449L196.135 14.886C195.623 15.5194 194.984 15.9945 194.212 16.3176C193.44 16.6406 192.606 16.8053 191.701 16.8053L191.682 16.818Z"
                  fill="#171E39"
                />
                <path
                  d="M206.002 16.818C204.908 16.818 203.921 16.5646 203.042 16.0642C202.163 15.5638 201.467 14.8606 200.961 13.9548C200.449 13.0553 200.196 12.0165 200.196 10.8446C200.196 9.67272 200.449 8.61486 200.961 7.72171C201.473 6.82855 202.163 6.13176 203.042 5.625C203.921 5.12458 204.908 4.8712 206.002 4.8712C207.039 4.8712 207.969 5.11191 208.804 5.59333C209.639 6.07475 210.297 6.75887 210.784 7.64569C211.271 8.53252 211.518 9.59671 211.518 10.8446C211.518 12.0925 211.277 13.1187 210.797 14.0245C210.316 14.924 209.658 15.6208 208.829 16.0959C207.995 16.5773 207.052 16.818 206.002 16.818ZM206.072 15.8045C206.97 15.8045 207.779 15.5954 208.494 15.1837C209.209 14.772 209.772 14.1892 210.183 13.4354C210.594 12.6816 210.803 11.8201 210.803 10.8446C210.803 9.86909 210.594 8.98226 210.183 8.24113C209.772 7.5 209.209 6.91723 208.494 6.50549C207.779 6.09375 206.976 5.88471 206.072 5.88471C205.167 5.88471 204.364 6.09375 203.649 6.50549C202.934 6.91723 202.371 7.5 201.96 8.24113C201.549 8.98226 201.341 9.85008 201.341 10.8446C201.341 11.8391 201.549 12.6879 201.96 13.4354C202.371 14.1892 202.934 14.772 203.649 15.1837C204.364 15.5954 205.167 15.8045 206.072 15.8045ZM210.822 16.7293V12.8716L211.024 10.8193L210.797 8.76689V0H211.922V16.7293H210.822Z"
                  fill="#171E39"
                />
                <path
                  d="M222.568 16.818C221.385 16.818 220.335 16.5646 219.43 16.0515C218.519 15.5384 217.811 14.8353 217.292 13.9295C216.774 13.03 216.514 11.9975 216.514 10.8383C216.514 9.67906 216.761 8.63387 217.248 7.74071C217.735 6.84755 218.406 6.14443 219.266 5.63134C220.12 5.11825 221.081 4.86487 222.15 4.86487C223.219 4.86487 224.174 5.11191 225.022 5.606C225.869 6.10009 226.54 6.79688 227.027 7.69004C227.514 8.5832 227.76 9.61571 227.76 10.7876C227.76 10.8319 227.76 10.8826 227.748 10.9333C227.741 10.984 227.735 11.041 227.735 11.1043H217.356V10.2238H227.128L226.679 10.6546C226.692 9.73607 226.502 8.91259 226.103 8.18413C225.705 7.45566 225.167 6.88556 224.497 6.47382C223.82 6.06208 223.042 5.85304 222.156 5.85304C221.271 5.85304 220.512 6.06208 219.829 6.47382C219.146 6.88556 218.608 7.45566 218.222 8.18413C217.83 8.91259 217.634 9.7424 217.634 10.6736V10.8763C217.634 11.8391 217.849 12.6879 218.273 13.4354C218.697 14.1829 219.291 14.7593 220.038 15.171C220.79 15.5828 221.644 15.7918 222.606 15.7918C223.358 15.7918 224.06 15.6588 224.712 15.3864C225.363 15.114 225.914 14.7023 226.369 14.1449L227.02 14.886C226.508 15.5194 225.869 15.9945 225.098 16.3176C224.326 16.6406 223.491 16.8053 222.587 16.8053L222.568 16.818Z"
                  fill="#171E39"
                />
                <path
                  d="M232.359 16.7293V4.9599H233.44V8.18414L233.327 7.82307C233.655 6.87924 234.212 6.14444 234.99 5.63769C235.768 5.12459 236.748 4.87122 237.919 4.87122V5.97341C237.874 5.97341 237.83 5.97341 237.786 5.96074C237.741 5.95441 237.697 5.94807 237.653 5.94807C236.35 5.94807 235.325 6.35981 234.592 7.17696C233.858 7.9941 233.491 9.13431 233.491 10.5912V16.723H232.365L232.359 16.7293Z"
                  fill="#171E39"
                />
                <path d="M242.15 16.7293V0H243.276V16.7293H242.15Z" fill="#171E39" />
                <path
                  d="M252.327 16.818C251.454 16.818 250.702 16.6786 250.063 16.3999C249.424 16.1212 248.937 15.7285 248.602 15.2154C248.266 14.7023 248.096 14.1195 248.096 13.4544C248.096 12.8526 248.235 12.3079 248.513 11.8201C248.791 11.3324 249.253 10.9333 249.898 10.6229C250.543 10.3125 251.404 10.1605 252.485 10.1605H256.805V11.041H252.504C251.29 11.041 250.436 11.269 249.949 11.7188C249.462 12.1685 249.215 12.7323 249.215 13.4101C249.215 14.1639 249.5 14.7656 250.069 15.2154C250.638 15.6651 251.442 15.8932 252.479 15.8932C253.516 15.8932 254.307 15.6651 254.99 15.2154C255.673 14.7656 256.179 14.1132 256.508 13.2517L256.824 14.0182C256.508 14.8733 255.97 15.5574 255.218 16.0579C254.459 16.5646 253.497 16.8117 252.327 16.8117V16.818ZM256.559 16.7293V14.0435L256.514 13.6381V9.21666C256.514 8.12079 256.217 7.28465 255.623 6.71454C255.028 6.14444 254.161 5.85939 253.023 5.85939C252.213 5.85939 251.461 5.99875 250.759 6.27747C250.063 6.55618 249.462 6.91091 248.969 7.34799L248.406 6.53718C248.994 6.01142 249.696 5.59968 250.524 5.30829C251.347 5.01691 252.213 4.87122 253.111 4.87122C254.566 4.87122 255.686 5.23861 256.464 5.97341C257.242 6.70821 257.634 7.81041 257.634 9.26733V16.7293H256.552H256.559Z"
                  fill="#171E39"
                />
                <path
                  d="M269.139 4.87122C270.101 4.87122 270.936 5.05492 271.65 5.42231C272.365 5.78971 272.915 6.34714 273.314 7.08828C273.712 7.82941 273.908 8.75424 273.908 9.8501V16.723H272.782V9.93878C272.782 8.61488 272.447 7.6077 271.783 6.91725C271.113 6.22679 270.183 5.87839 268.981 5.87839C268.064 5.87839 267.273 6.06843 266.597 6.44216C265.92 6.81589 265.407 7.34166 265.053 8.01944C264.699 8.69723 264.522 9.50804 264.522 10.4519V16.7167H263.396V4.9599H264.478V8.22848L264.32 7.86742C264.68 6.93625 265.275 6.20145 266.097 5.66936C266.919 5.13726 267.937 4.87122 269.133 4.87122H269.139Z"
                  fill="#171E39"
                />
                <path
                  d="M284.199 16.818C283.105 16.818 282.118 16.5646 281.239 16.0642C280.36 15.5638 279.664 14.8606 279.158 13.9548C278.646 13.0553 278.393 12.0165 278.393 10.8446C278.393 9.67272 278.646 8.61486 279.158 7.72171C279.671 6.82855 280.36 6.13176 281.239 5.625C282.118 5.12458 283.105 4.8712 284.199 4.8712C285.237 4.8712 286.166 5.11191 287.001 5.59333C287.836 6.07475 288.494 6.75887 288.981 7.64569C289.468 8.53252 289.715 9.59671 289.715 10.8446C289.715 12.0925 289.474 13.1187 288.994 14.0245C288.513 14.924 287.855 15.6208 287.027 16.0959C286.192 16.5773 285.249 16.818 284.199 16.818ZM284.269 15.8045C285.167 15.8045 285.977 15.5954 286.691 15.1837C287.406 14.772 287.969 14.1892 288.38 13.4354C288.791 12.6816 289 11.8201 289 10.8446C289 9.86909 288.791 8.98226 288.38 8.24113C287.969 7.5 287.406 6.91723 286.691 6.50549C285.977 6.09375 285.173 5.88471 284.269 5.88471C283.364 5.88471 282.561 6.09375 281.846 6.50549C281.132 6.91723 280.569 7.5 280.158 8.24113C279.746 8.98226 279.538 9.85008 279.538 10.8446C279.538 11.8391 279.746 12.6879 280.158 13.4354C280.569 14.1892 281.132 14.772 281.846 15.1837C282.561 15.5954 283.364 15.8045 284.269 15.8045ZM289.019 16.7293V12.8716L289.221 10.8193L288.994 8.76689V0H290.12V16.7293H289.019Z"
                  fill="#171E39"
                />
                <path
                  d="M298.968 16.818C297.994 16.818 297.084 16.6723 296.236 16.3873C295.388 16.1022 294.731 15.7411 294.269 15.3041L294.788 14.4046C295.237 14.7973 295.837 15.133 296.59 15.4054C297.343 15.6841 298.152 15.8235 299.019 15.8235C300.252 15.8235 301.138 15.6208 301.676 15.2154C302.213 14.81 302.485 14.2779 302.485 13.6128C302.485 13.1313 302.346 12.7513 302.068 12.4726C301.789 12.1938 301.416 11.9785 300.955 11.8328C300.486 11.6807 299.98 11.5604 299.424 11.459C298.867 11.364 298.311 11.25 297.76 11.1296C297.204 11.0093 296.691 10.8383 296.23 10.6102C295.762 10.3822 295.395 10.0718 295.116 9.67272C294.838 9.27365 294.699 8.74789 294.699 8.08277C294.699 7.481 294.863 6.94257 295.192 6.46115C295.521 5.97973 296.015 5.59333 296.679 5.30195C297.337 5.01056 298.165 4.86487 299.152 4.86487C299.886 4.86487 300.626 4.97255 301.372 5.18159C302.112 5.39063 302.719 5.66934 303.181 6.01774L302.662 6.91723C302.169 6.5435 301.612 6.27112 300.999 6.10642C300.385 5.94173 299.759 5.85938 299.133 5.85938C297.994 5.85938 297.153 6.06842 296.622 6.49282C296.09 6.9109 295.825 7.43666 295.825 8.0701C295.825 8.56419 295.964 8.96326 296.242 9.25465C296.521 9.54603 296.894 9.77407 297.356 9.93244C297.824 10.0908 298.33 10.2175 298.886 10.3188C299.443 10.4139 299.999 10.5279 300.55 10.6482C301.106 10.7686 301.612 10.9396 302.08 11.155C302.548 11.3704 302.915 11.6744 303.194 12.0545C303.472 12.4409 303.611 12.954 303.611 13.6001C303.611 14.2462 303.434 14.81 303.08 15.2914C302.726 15.7728 302.207 16.1465 301.517 16.4189C300.828 16.6913 299.98 16.8243 298.975 16.8243L298.968 16.818Z"
                  fill="#171E39"
                />
                <path
                  d="M312.656 16.818C311.473 16.818 310.423 16.5646 309.519 16.0515C308.608 15.5384 307.899 14.8353 307.381 13.9295C306.862 13.03 306.603 11.9975 306.603 10.8383C306.603 9.67906 306.849 8.63387 307.337 7.74071C307.824 6.84755 308.494 6.14443 309.354 5.63134C310.208 5.11825 311.17 4.86487 312.238 4.86487C313.307 4.86487 314.263 5.11191 315.11 5.606C315.958 6.10009 316.628 6.79688 317.115 7.69004C317.602 8.5832 317.849 9.61571 317.849 10.7876C317.849 10.8319 317.849 10.8826 317.836 10.9333C317.83 10.984 317.824 11.041 317.824 11.1043H307.444V10.2238H317.216L316.767 10.6546C316.78 9.73607 316.59 8.91259 316.192 8.18413C315.793 7.45566 315.256 6.88556 314.585 6.47382C313.908 6.06208 313.13 5.85304 312.245 5.85304C311.359 5.85304 310.6 6.06208 309.917 6.47382C309.234 6.88556 308.696 7.45566 308.311 8.18413C307.918 8.91259 307.722 9.7424 307.722 10.6736V10.8763C307.722 11.8391 307.937 12.6879 308.361 13.4354C308.785 14.1829 309.38 14.7593 310.126 15.171C310.879 15.5828 311.732 15.7918 312.694 15.7918C313.447 15.7918 314.149 15.6588 314.8 15.3864C315.452 15.114 316.002 14.7023 316.457 14.1449L317.109 14.886C316.596 15.5194 315.958 15.9945 315.186 16.3176C314.414 16.6406 313.579 16.8053 312.675 16.8053L312.656 16.818Z"
                  fill="#171E39"
                />
              </g>
              <defs>
                <clipPath id="clip0_662_5200">
                  <rect width="319" height="60" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </LosseLink>

          <HeaderDesktop />
          {/* <HeaderMobile /> */}
        </div>
      </div>
      {desktopMenuOpen && (
        <div className={clsx('fixed left-0 top-0 z-50 flex h-screen-dynamic w-screen items-center justify-center bg-site-accent')}>
          <div
            className="absolute right-10 top-10 text-center"
            role="button"
            onClick={() => {
              setDesktopMenuOpen(!desktopMenuOpen)
              setScrollable(true)
            }}
            aira-label="Close menu"
          >
            <div className="relative ml-4 h-8 w-8 before:absolute before:h-[33px] before:w-[3px] before:rotate-45 before:bg-site-base before:[content:''] after:absolute after:h-[33px] after:w-[3px] after:-rotate-45 after:bg-site-base after:[content:'']" />
          </div>
          <div className="container max-w-screen-lg mx-auto flex justify-between items-center">
            <ul className="m-0 relative z-20 pl-0 animate-fade-up animate-duration-500 animate-ease-in-out">
              <HeaderMenu />
              <SubMenu />
            </ul>
            <svg
              className="max-lg:opacity-70 max-lg:absolute z-10 animate-fade-up animate-delay-200 animate-duration-500 animate-ease-in-out "
              width="514"
              height="647"
              viewBox="0 0 514 647"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_469_2106)">
                <path
                  d="M269.103 142.646C177.484 142.646 102.967 225.333 102.967 326.988C102.967 428.644 177.484 511.331 269.103 511.331C360.723 511.331 435.24 428.644 435.24 326.988C435.24 225.333 360.723 142.646 269.103 142.646ZM389.205 239.929C394.381 247.13 392.709 257.193 385.508 262.369L340.566 294.582C343.556 304.709 345.196 315.607 345.196 326.988C345.196 338.369 343.653 348.432 340.888 358.237L385.508 390.225C392.709 395.401 394.381 405.432 389.205 412.666C386.055 417.038 381.136 419.385 376.121 419.385C372.874 419.385 369.595 418.42 366.766 416.363L327.129 387.943C318.16 401.028 306.394 411.026 292.989 416.556V466.354C292.989 475.228 285.788 482.429 276.915 482.429C268.043 482.429 260.842 475.228 260.842 466.354V420.799C240.846 418.131 223.133 405.786 210.821 387.654L170.765 416.395C167.936 418.42 164.657 419.417 161.411 419.417C156.396 419.417 151.477 417.102 148.327 412.698C143.151 405.496 144.823 395.434 152.024 390.258L197.158 357.884C194.458 348.207 192.947 337.823 192.947 327.02C192.947 316.218 194.554 304.998 197.48 294.968L152.024 262.369C144.823 257.193 143.151 247.162 148.327 239.929C153.502 232.727 163.532 231.055 170.765 236.231L211.464 265.423C223.744 247.805 241.168 235.878 260.809 233.242V187.686C260.809 178.813 268.01 171.612 276.883 171.612C285.756 171.612 292.957 178.813 292.957 187.686V237.485C306.04 242.854 317.581 252.531 326.454 265.166L366.766 236.264C373.967 231.088 384.029 232.759 389.205 239.961V239.929Z"
                  fill="#F2DFCE"
                />
                <path
                  d="M497.926 172.801H479.828V102.813C479.828 76.7078 461.665 54.2356 437.619 50.5384L111.55 0.51447C98.1451 -1.54307 84.4183 2.47556 73.874 11.5416C62.1403 21.6043 55.3895 36.65 55.3895 52.821V594.179C55.3895 610.222 62.0439 625.203 73.649 635.266C82.4895 642.949 93.2266 647 104.414 647C106.6 647 108.818 646.839 111.036 646.518L437.072 600.095C461.407 596.622 479.763 574.118 479.763 547.756V514.867H497.862C506.735 514.867 513.936 507.666 513.936 498.793V433.916C513.936 425.043 506.735 417.842 497.862 417.842H479.763V269.859H497.862C506.735 269.859 513.936 262.658 513.936 253.785V188.908C513.936 180.035 506.735 172.833 497.862 172.833L497.926 172.801ZM447.681 547.724C447.681 558.204 441.187 567.013 432.604 568.235L106.535 614.658C101.199 615.462 97.1807 613.051 94.7375 610.929C90.1726 606.974 87.5687 600.834 87.5687 594.115V52.7889C87.5687 46.0376 90.2048 39.8649 94.8339 35.9106C96.9557 34.1102 100.235 32.117 104.51 32.117C105.217 32.117 105.925 32.1813 106.696 32.2778L432.732 82.3017C441.251 83.6198 447.681 92.4287 447.681 102.813V547.724Z"
                  fill="#F2DFCE"
                />
                <path
                  d="M43.6879 551.389L43.5915 550.81C40.6018 551.228 38.2551 549.846 36.8084 548.624C33.883 546.084 32.1471 541.905 32.1471 537.468V102.973C32.1471 98.5048 33.9152 94.3254 36.8727 91.7856C38.3193 90.564 40.6661 89.1815 43.6879 89.6316V57.4504C33.6902 57.1611 23.7889 60.6653 15.945 67.3845C5.81863 76.0647 0 89.0529 0 102.973V537.436C0 551.26 5.75433 564.184 15.7521 572.864C23.3388 579.423 32.8543 582.991 42.5306 582.991C42.9164 582.991 43.27 582.991 43.6558 582.959V551.357L43.6879 551.389Z"
                  fill="#F2DFCE"
                />
              </g>
              <defs>
                <clipPath id="clip0_469_2106">
                  <rect width="514" height="647" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      )}
    </nav>
  )
}
