import { useLoaderData } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import type { ContentNode, Page_Flexcontent_Flex_Posts, Post as PostType } from '~/graphql/types'
import Post from './post-types/Post'
import Circle from '~/components/elements/Circle'
import { useEffect, useRef, useState } from 'react'
import { useInView } from 'framer-motion'

type Edge<T = ContentNode> = {
  node: T & {
    __typename: string
  }
}

const getPostTypeName = (fields: Page_Flexcontent_Flex_Posts) => {
  const items = fields?.posts?.edges as Edge[]
  return items?.[0]?.node?.__typename
}

const PostTypes: { [key: string]: any } = {
  Post: Post
}

export default function NewsOther({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const postTypeName = getPostTypeName(fields)
  const [posts, setPosts] = useState(fields?.posts?.edges)
  const { page } = useLoaderData<{ page: PostType }>()

  const titleRef = useRef(null)
  const postsRef = useRef(null)

  const titleVisible = useInView(titleRef, { once: true })
  const postsVisible = useInView(postsRef, { once: true })

  useEffect(() => {
    setPosts(fields?.posts?.edges?.filter((edge) => edge?.node?.databaseId !== page?.databaseId))
  }, [fields?.posts?.edges, page?.databaseId])

  if (posts.length === 0) return null

  return (
    <section data-component="NewsOther" className="section">
      <div className="container py-6 sm:py-10 relative">
        <Circle color="#F2DFCE" className="absolute w-[220px] lg:w-[309px] top-0 -right-10" />
        <div
          ref={titleRef}
          className={clsx(
            titleVisible && 'animate-fade-right animate-delay-100 animate-duration-500 animate-ease-in-out',
            titleVisible ? 'opacity-100' : 'opacity-0',
            'flex lg:pl-10 items-center gap-12'
          )}
        >
          <h2 className="title !text-site-nav">{fields?.title}</h2>
        </div>
        <div
          ref={postsRef}
          className={clsx(
            postsVisible && 'animate-fade-up animate-delay-300 animate-duration-500 animate-ease-in-out',
            postsVisible ? 'opacity-100' : 'opacity-0',
            'flex lg:grid grid-cols-7 relative max-lg:overflow-x-scroll pb-5 h-fit mt-8 lg:mt-16 gap-4 xl:gap-10'
          )}
        >
          {posts?.slice(0, 3).map((edge, index) => {
            if (!edge || !edge?.node) return null

            const Component = PostTypes[postTypeName] || PostTypes.Faq

            return (
              <div
                key={edge?.node?.uri}
                className={clsx(index === 0 ? 'col-span-3' : 'col-span-2', 'max-sm:min-w-[300px] max-lg:min-w-[400px]')}
              >
                <Component data={edge?.node} />
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}
