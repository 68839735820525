import { LosseLink } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import type { ContentNode, Page_Flexcontent_Flex_Posts } from '~/graphql/types'
import Post from './post-types/Post'
import Circle from '~/components/elements/Circle'
import { useRef } from 'react'
import { useInView } from 'framer-motion'

type Edge<T = ContentNode> = {
  node: T & {
    __typename: string
  }
}

const getPostTypeName = (fields: Page_Flexcontent_Flex_Posts) => {
  const items = fields.posts?.edges as Edge[]
  return items?.[0]?.node?.__typename
}

const PostTypes: { [key: string]: any } = {
  Post: Post
}

export default function PostsLatest({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const postTypeName = getPostTypeName(fields)

  const titleRef = useRef(null)
  const postsRef = useRef(null)

  const titleVisible = useInView(titleRef, { once: true })
  const postsVisible = useInView(postsRef, { once: true })

  return (
    <section data-component="PostsLatest" className="section">
      <div className="container py-6 sm:py-10 relative">
        <Circle color="#F2DFCE" className="absolute w-[220px] lg:w-[309px] top-0 -right-10" />
        <div
          ref={titleRef}
          className={clsx(
            titleVisible && 'animate-fade-right animate-delay-100 animate-duration-500 animate-ease-in-out',
            titleVisible ? 'opacity-100' : 'opacity-0',
            'flex lg:pl-10 items-center gap-12'
          )}
        >
          <h2 className="title !text-site-nav">{fields?.title}</h2>
          <SeeAllLink className="sm:flex hidden" fields={fields} />
        </div>
        <div
          ref={postsRef}
          className={clsx(
            postsVisible && 'animate-fade-up animate-delay-300 animate-duration-500 animate-ease-in-out',
            postsVisible ? 'opacity-100' : 'opacity-0',
            'flex lg:grid grid-cols-7 relative max-lg:overflow-x-scroll pb-5 h-fit mt-8 lg:mt-16 gap-4 xl:gap-10 max-sm:-mr-4'
          )}
        >
          {fields?.posts?.edges?.slice(0, 3).map((edge, index) => {
            if (!edge || !edge?.node) return null

            const Component = PostTypes[postTypeName] || PostTypes.Faq

            return (
              <div
                key={edge?.node?.uri}
                className={clsx(index === 0 ? 'col-span-3' : 'col-span-2', 'max-sm:min-w-[300px] max-lg:min-w-[400px]')}
              >
                <Component data={edge?.node} />
              </div>
            )
          })}
        </div>
        <div className="flex sm:hidden mt-5 justify-end">
          <SeeAllLink className="" fields={fields} />
        </div>
      </div>
    </section>
  )
}

const SeeAllLink = ({ className, fields }: { className: string; fields: Page_Flexcontent_Flex_Posts }) => {
  return (
    <LosseLink
      target={fields?.link?.target}
      className={clsx(className, 'flex group items-center gap-2 lg:gap-3 lg:text-base text-sm text-site-nav')}
      to={fields?.link?.url}
    >
      <div className="relative smooth z-10">
        <div className="w-5 lg:w-10 mr-5 h-5 lg:h-10 bg-site-alternative rounded-full" />
        <svg
          className="absolute w-[21px] lg:w-[42px] h-[12px] lg:h-[24px] smooth group-hover:left-5 lg:group-hover:left-7 top-0 bottom-0 my-auto left-3 lg:left-5"
          viewBox="0 0 42 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M41.0607 13.0607C41.6464 12.4749 41.6464 11.5251 41.0607 10.9393L31.5147 1.3934C30.9289 0.807611 29.9792 0.807611 29.3934 1.3934C28.8076 1.97919 28.8076 2.92893 29.3934 3.51472L37.8787 12L29.3934 20.4853C28.8076 21.0711 28.8076 22.0208 29.3934 22.6066C29.9792 23.1924 30.9289 23.1924 31.5147 22.6066L41.0607 13.0607ZM0 13.5H40V10.5H0V13.5Z"
            fill="#F0831F"
          />
        </svg>
      </div>
      <span className="group-hover:underline">{fields?.link?.title}</span>
    </LosseLink>
  )
}
