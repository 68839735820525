import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import BannerDefault from './BannerDefault'
import BannerHome from './BannerHome'
import BannerWithLink from './BannerWithLink'
import BannerLogin from './BannerLogin'
import BannerDetail from './BannerDetail'

export default function BannerShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    default: BannerDefault,
    home: BannerHome,
    withlink: BannerWithLink,
    login: BannerLogin,
    detail: BannerDetail
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
