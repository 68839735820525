import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { useRef } from 'react'
import Button from '~/components/elements/Button'
import Circle from '~/components/elements/Circle'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'
import { useInView } from 'framer-motion'

export default function ContentSteps({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const containerRef = useRef(null)
  const stepsRef = useRef(null)

  const containerVisible = useInView(containerRef, { once: true })
  const stepsVisible = useInView(stepsRef, { once: true })

  return (
    <section data-component="ContentSteps" className="section">
      <div
        ref={containerRef}
        className={clsx(
          containerVisible && 'animate-fade-up animate-delay-100 animate-duration-500 animate-ease-in-out',
          containerVisible ? 'opacity-100' : 'opacity-0',
          'container flex flex-col gap-8 lg:gap-16'
        )}
      >
        <div className="max-w-[800px] mx-auto">
          <h2 className="text-3xl sm:text-4xl xl:text-5xl font-bold text-site-nav lg:text-center">{fields?.title}</h2>
        </div>
        <div
          ref={stepsRef}
          className={clsx(
            stepsVisible && 'animate-fade-right animate-delay-500 animate-duration-500 animate-ease-in-out',
            stepsVisible ? 'opacity-100' : 'opacity-0',
            'flex lg:flex-row flex-col max-lg:gap-7 justify-center'
          )}
        >
          {fields?.items?.map((item, index) => (
            <div className="lg:w-[220px] xl:w-[295px] group relative flex flex-row lg:flex-col items-center gap-5 lg:gap-9" key={index}>
              <div className="bg-white max-lg:w-[100px] max-lg:min-w-[100px] relative rounded-full lg:w-[150px] xl:w-[230px] flex justify-center items-center aspect-square">
                <Circle
                  color="#B0CBD3"
                  className="absolute w-[8px] lg:w-[18px] max-lg:left-0 max-lg:right-0 max-lg:mx-auto max-lg:-top-3 lg:top-0 lg:bottom-0 lg:my-auto lg:-left-7"
                />
                <Circle
                  color="#B0CBD3"
                  className="absolute w-[8px] lg:w-[18px] max-lg:left-0 max-lg:right-0 max-lg:mx-auto max-lg:-bottom-3 lg:top-0 lg:bottom-0 lg:my-auto lg:-right-7"
                />
                <Circle
                  color="#F2DFCE"
                  className="absolute w-[50px] lg:w-[70px] xl:w-[96px] top-5 left-5 lg:top-8 lg:left-8 xl:top-14 xl:left-14"
                />

                <LossePlaatjie maxWidth={100} className="w-[50px] lg:w-[80px] ml-2 mt-2 lg:ml-4 lg:mt-4 relative z-10" src={item?.image} />
              </div>
              <h3 className="text-lg xs:text-xl xl:text-2xl lg:px-12 text-site-nav font-bold lg:text-center">{item?.title}</h3>
            </div>
          ))}
        </div>
        {fields?.links && (
          <div className="flex justify-center items-center gap-2 mt-4">
            {fields?.links?.map((link, index) => (
              <Button
                className={clsx(index === 2 && 'btn--outline', index === 1 && 'btn--alt', 'btn')}
                key={index}
                to={link?.link?.url || '/'}
                target={link?.link?.target}
              >
                {link?.link?.title}
              </Button>
            ))}
          </div>
        )}
      </div>
    </section>
  )
}
