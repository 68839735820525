import type { Page_Flexcontent_Flex_Posts, Post as PostProps } from '~/graphql/types'
import Slider, { Slide } from '~/components/elements/Slider'
import Post from './post-types/Post'
import { Autoplay } from 'swiper/modules'

export default function PostsHighlighted({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  return (
    <section data-component="Highlighted" className="section">
      <div className="container">
        <Slider
          modules={[Autoplay]}
          autoplay
          breakpoints={{
            0: {
              slidesPerView: 3,
              spaceBetween: 30
            }
          }}
          className="rounded-xl overflow-hidden"
        >
          {fields?.posts?.edges?.map((item) => (
            <Slide key={item?.node?.databaseId} className="max-w-[calc(33%-30px)]">
              <Post data={item?.node as PostProps} />
            </Slide>
          ))}
        </Slider>
      </div>
    </section>
  )
}
