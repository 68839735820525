import { useState } from 'react'
import Content from '~/components/elements/Content'
import type { VeelgesteldeVraag as Props } from '~/graphql/types'

export default function Faq({ data }: { data: Props }) {
  const [opened, setOpened] = useState(false)

  return (
    <div className="block py-[20px] sm:py-[25px] lg:py-[35px]">
      <div onClick={() => setOpened(!opened)} className="group grid cursor-pointer grid-cols-10">
        <h2 className="group-hover:underline col-span-9 text-base sm:text-lg font-bold text-site-nav">{data?.recap?.question}</h2>
        <div className="col-span-1 flex justify-end items-center">
          {opened ? (
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_649_3151)">
                <path d="M14 6.4165H0V7.58318H14V6.4165Z" fill="#2F6F7D" />
              </g>
              <defs>
                <clipPath id="clip0_649_3151">
                  <rect width="14" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg>
          ) : (
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_649_3153)">
                <path d="M14 6.41667H7.58333V0H6.41667V6.41667H0V7.58333H6.41667V14H7.58333V7.58333H14V6.41667Z" fill="#2F6F7D" />
              </g>
              <defs>
                <clipPath id="clip0_649_3153">
                  <rect width="14" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg>
          )}
        </div>
      </div>
      {opened && (
        <div className="pt-4 sm:pt-8 animate-fade-down animate-duration-500 animate-delay-100 animate-ease-in-out">
          <Content className="content">{data?.recap?.answer}</Content>
        </div>
      )}
    </div>
  )
}
