import { LosseBlogBink, type LosseBlogBinkReadableParams, LossePlaatjie, useLosseBlogBink, useSearchParams } from '@ubo/losse-sjedel'
import Breadcrumbs from '~/components/elements/Breadcrumbs'
import type { ContentNode, Maybe, Page_Flexcontent_Flex_Posts, WpPageInfo } from '~/graphql/types'
import Post from './post-types/Post'
import Faq from './post-types/Faq'
import Circle from '~/components/elements/Circle'
import { DebounceInput } from 'react-debounce-input'

export const READABLE_PARAMS: LosseBlogBinkReadableParams = {
  filters: 'onderwerp'
}

export default function FaqOverview({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  return (
    <section data-component="FaqOverview" className="section">
      <div className="container sm:pb-20">
        <div className="relative">
          <LosseBlogBink pageInfo={fields?.posts?.pageInfo as Maybe<WpPageInfo> | undefined} readableParams={READABLE_PARAMS}>
            <Overview fields={fields} />
          </LosseBlogBink>
          {/* <Circle color="#F2DFCE" className="w-[309px] z-10 absolute -bottom-[90px] -right-[50px] xl:right-[50px]" /> */}
        </div>
      </div>
    </section>
  )
}

type Edge<T = ContentNode> = {
  node: T & {
    __typename: string
  }
}

const getPostTypeName = (fields: Page_Flexcontent_Flex_Posts) => {
  const items = fields.posts?.edges as Edge[]
  return items?.[0]?.node?.__typename
}

const PostTypes: { [key: string]: any } = {
  Post: Post,
  VeelgesteldeVraag: Faq
}

function Overview({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const postTypeName = getPostTypeName(fields)
  const [searchParams, setSearchParams] = useSearchParams()
  const { isFilterActive } = useLosseBlogBink()

  console.log(fields)

  function handleSearchInput(event: React.ChangeEvent<HTMLInputElement>) {
    // Debounced search
    const value = event.target.value
    setSearchParams(
      { search: value },
      {
        state: {
          scroll: false
        }
      }
    )
  }

  // grab the categories from fields.posts and group them
  const categoriePosts = fields?.posts?.edges?.reduce((acc: any, edge: any) => {
    if (!edge?.node?.categories) return acc
    edge.node.categories.edges.forEach((category: any) => {
      if (!category?.node?.name) return
      if (!acc[category.node.name]) {
        acc[category.node.name] = []
      }
      acc[category.node.name].push(edge)
    })
    return acc
  }, {})

  // change the order of the categories to notaris, over de kluis, veiligheid, toekomst
  const order = ['Notaris', 'Over de kluis', 'Veiligheid', 'Toekomst']
  const orderedCategoriePosts = Object.keys(categoriePosts)
    .sort((a, b) => order.indexOf(a) - order.indexOf(b))
    .reduce((acc: { [key: string]: any[] }, key) => {
      acc[key] = categoriePosts[key]
      return acc
    }, {})

  console.log(searchParams.size)

  return (
    <div>
      <div className="bg-site-base rounded-[25px] animate-fade-up animate-duration-500 animate-ease-in-out sm:rounded-[50px] py-6 sm:py-10 px-4 xs:px-6 sm:px-10 xl:px-14">
        <div className="grid grid-cols-1 lg:grid-cols-5 lg:gap-10 xl:gap-10">
          <div className="sm:pb-5 xl:pb-[40px] col-span-4">
            <div>
              <Breadcrumbs />
            </div>
            <div className="flex flex-col justify-between sm:h-full sm:pl-5 xl:pl-10 pt-6 xl:pt-11">
              <h1 className="title lg:pb-5 !text-white">{fields?.title}</h1>
              <div className="flex max-md:flex-wrap gap-4 lg:gap-10">
                <div className="bg-site-secondary max-sm:mt-4 w-full lg:w-fit rounded-[12px] lg:rounded-[25px] pt-3 sm:pt-6 lg:pt-11 px-6 sm:px-6 lg:px-9 pb-4 sm:pb-6 lg:pb-9">
                  <div className="text-site-nav text-lg sm:text-2xl pb-2 sm:pb-5 font-bold">{fields?.subtitle}</div>
                  <div className="relative">
                    <DebounceInput
                      defaultValue={searchParams.get('search')}
                      onChange={(e) => {
                        handleSearchInput(e)
                      }}
                      className="rounded-full text-site-nav w-full py-[10px] px-5"
                      name="search"
                      type="text"
                      placeholder="Zoeken"
                      minLength={2}
                      debounceTimeout={500}
                    />
                    <svg
                      className="absolute top-0 bottom-0 right-5 my-auto"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="01 align center">
                        <path
                          id="Vector"
                          d="M23.9998 22.5867L17.7378 16.3247C19.3644 14.3353 20.1642 11.7968 19.9716 9.23426C19.7791 6.67173 18.609 4.28123 16.7034 2.55722C14.7977 0.833208 12.3024 -0.0924103 9.73342 -0.0281784C7.16447 0.0360534 4.71848 1.08522 2.9014 2.90231C1.08431 4.7194 0.0351378 7.16539 -0.029094 9.73434C-0.0933258 12.3033 0.832292 14.7987 2.5563 16.7043C4.28031 18.6099 6.67081 19.78 9.23334 19.9725C11.7959 20.1651 14.3344 19.3653 16.3238 17.7387L22.5858 24.0007L23.9998 22.5867ZM9.99978 18.0007C8.41753 18.0007 6.87081 17.5315 5.55522 16.6525C4.23963 15.7734 3.21425 14.524 2.60875 13.0622C2.00324 11.6004 1.84482 9.99182 2.1535 8.43997C2.46218 6.88813 3.22411 5.46266 4.34293 4.34384C5.46175 3.22502 6.88721 2.4631 8.43906 2.15441C9.99091 1.84573 11.5994 2.00416 13.0612 2.60966C14.5231 3.21516 15.7725 4.24054 16.6515 5.55614C17.5306 6.87173 17.9998 8.41845 17.9998 10.0007C17.9974 12.1217 17.1538 14.1551 15.654 15.6549C14.1542 17.1547 12.1208 17.9983 9.99978 18.0007Z"
                          fill="#2F6F7D"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
                <div className="bg-site-secondary max-sm:mt-4 w-full lg:w-fit rounded-[12px] lg:rounded-[25px] pt-3 sm:pt-6 lg:pt-11 px-6 sm:px-6 lg:px-9 pb-4 sm:pb-6 lg:pb-9">
                  <div className="flex items-center justify-between gap-6 pb-2 sm:pb-5">
                    <div className="text-site-nav text-lg sm:text-2xl font-bold">Filteren</div>
                    {searchParams && searchParams.size > 0 && (
                      <div
                        onClick={() => {
                          // Delete all searchParams
                          setSearchParams(new URLSearchParams(), {
                            state: {
                              scroll: false
                            }
                          })
                        }}
                        className="text-site-nav text-base cursor-pointer lg:text-xl font-light underline"
                      >
                        Reset filters
                      </div>
                    )}
                  </div>
                  <div className="relative">
                    {fields?.filters
                      ?.filter((e) => e?.parentDatabaseId === null)
                      .map((filter, index) => {
                        return (
                          <div key={index} className="relative w-full lg:w-[unset]">
                            <svg
                              className="absolute pointer-events-none z-20 top-0 bottom-0 right-7 my-auto"
                              width="14"
                              height="9"
                              viewBox="0 0 14 9"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13.7098 1.20998C13.6169 1.11625 13.5063 1.04186 13.3844 0.991091C13.2625 0.940323 13.1318 0.914185 12.9998 0.914185C12.8678 0.914185 12.7371 0.940323 12.6152 0.991091C12.4934 1.04186 12.3828 1.11625 12.2898 1.20998L7.70982 5.78997C7.61685 5.8837 7.50625 5.9581 7.38439 6.00886C7.26253 6.05963 7.13183 6.08577 6.99982 6.08577C6.86781 6.08577 6.7371 6.05963 6.61524 6.00886C6.49338 5.9581 6.38278 5.8837 6.28982 5.78997L1.70982 1.20998C1.61685 1.11625 1.50625 1.04186 1.38439 0.991091C1.26253 0.940323 1.13183 0.914185 0.999816 0.914185C0.867804 0.914185 0.737098 0.940323 0.615239 0.991091C0.49338 1.04186 0.382779 1.11625 0.289816 1.20998C0.103565 1.39734 -0.000976562 1.6508 -0.000976562 1.91498C-0.000976562 2.17917 0.103565 2.43262 0.289816 2.61998L4.87982 7.20997C5.44232 7.77177 6.20481 8.08733 6.99982 8.08733C7.79482 8.08733 8.55732 7.77177 9.11982 7.20997L13.7098 2.61998C13.8961 2.43262 14.0006 2.17917 14.0006 1.91498C14.0006 1.6508 13.8961 1.39734 13.7098 1.20998Z"
                                fill="#543575"
                              />
                            </svg>
                            <select
                              className="bg-white relative focus-within:outline-iw-acai border !appearance-none border-white rounded-full h-[45px] lg:h-[54px] pl-7 pr-8 w-full lg:min-w-[311px] text-iw-acai children-option:color-unset ring-iw-acai placeholder:text-iw-acai/50"
                              onChange={(e) => {
                                setSearchParams(new URLSearchParams({ [READABLE_PARAMS.filters]: e?.target.value }), {
                                  state: {
                                    scroll: false
                                  }
                                })
                              }}
                            >
                              <option className="bg-white text-site-nav" disabled selected value="">
                                {filter?.name}
                              </option>
                              {fields.filters
                                ?.filter((e) => e?.parentDatabaseId === filter?.databaseId)
                                .map((f, subIndex) => {
                                  const isActive = isFilterActive(f?.name)
                                  return (
                                    <option className="bg-white text-site-nav" key={subIndex} selected={isActive} value={f?.name}>
                                      {f?.name}
                                    </option>
                                  )
                                })}
                            </select>
                          </div>
                        )
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-end col-span-1 items-center">
            <LossePlaatjie
              maxWidth={320}
              className="max-lg:absolute w-fit max-lg:object-contain max-lg:h-[90%] max-lg:top-0 max-lg:my-auto max-lg:bottom-0 max-lg:right-5 max-lg:-z-10"
              src={fields?.image}
            />
          </div>
        </div>
      </div>

      {Object.keys(orderedCategoriePosts).map((category, index) => {
        // check if the search term is in the question and if not return null
        if (searchParams.get('search')) {
          const filteredPosts = categoriePosts[category]?.filter((edge: any) => {
            // @ts-ignore
            return edge?.node?.recap?.question?.includes(searchParams.get('search'))
          })
          if (filteredPosts.length === 0) return null
        }

        return (
          <div
            key={index}
            className="bg-site-secondary animate-fade-up animate-delay-200 animate-duration-500 animate-ease-in-out relative z-20 mt-5 lg:mt-10 max-w-screen-lg mx-auto rounded-[25px] sm:rounded-[50px] px-8 sm:px-10 lg:px-24 py-5 sm:pb-10 sm:pt-14"
          >
            <div className="grid grid-cols-1">
              <h2 className="text-2xl lg:text-4xl mb-8 font-bold text-white">{category}</h2>
              <div className="grid grid-cols-1">
                {categoriePosts[category]?.map((edge: any, index: number) => {
                  if (!edge || !edge?.node) return null

                  const Component = PostTypes[postTypeName] || PostTypes.Faq

                  if (searchParams.get('search')) {
                    // @ts-ignore
                    if (!edge?.node?.recap?.question?.includes(searchParams.get('search'))) return null
                  }

                  return (
                    <div key={edge.node.uri} className="col-span-1">
                      {index !== 0 && <div className="bg-white h-[2px] rounded-full w-full" />}
                      <Component data={edge.node} />
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
