import { EmailShareButton, FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share'

export default function Share({ url }: { url: string }) {
  return (
    <div className="flex items-center gap-6">
      <div className="font-light text-white text-sm">Deel dit bericht</div>
      <div className="flex items-center gap-3">
        <LinkedinShareButton url={url}>
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.13086 4.375H0V14H3.13086V4.375Z" fill="#F9EADC" />
            <path
              d="M11.6583 4.48779C11.6252 4.47754 11.5934 4.46592 11.5585 4.45635C11.5165 4.44678 11.4745 4.43892 11.4317 4.43174C11.2656 4.39858 11.0834 4.375 10.8698 4.375C9.04463 4.375 7.88696 5.70254 7.50552 6.21523V4.375H4.375V14H7.50586V8.75C7.50586 8.75 9.87178 5.45474 10.8702 7.875V14H14.0003V7.50483C14.0003 6.05049 13.0037 4.83882 11.6587 4.48779H11.6583Z"
              fill="#F9EADC"
            />
            <path
              d="M3.0625 1.53125C3.0625 2.37686 2.37686 3.0625 1.53125 3.0625C0.685645 3.0625 0 2.37686 0 1.53125C0 0.685645 0.685645 0 1.53125 0C2.37686 0 3.0625 0.685645 3.0625 1.53125Z"
              fill="#F9EADC"
            />
          </svg>
        </LinkedinShareButton>
        <FacebookShareButton url={url}>
          <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.17515 14V7.61441H10.3177L10.6391 5.12509H8.17515V3.53603C8.17515 2.81554 8.3744 2.32453 9.40875 2.32453L10.7258 2.32399V0.097461C10.4981 0.0678617 9.71621 0 8.80621 0C6.90599 0 5.60507 1.15988 5.60507 3.28949V5.12509H3.45605V7.61441H5.60507V14H8.17515Z"
              fill="#F9EADC"
            />
          </svg>
        </FacebookShareButton>
        <TwitterShareButton url={url}>
          <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_652_3211)">
              <path
                d="M12.0689 -0.145813H14.4142L9.29181 5.90753L15.3178 14.1459H10.6006L6.90334 9.15064L2.67785 14.1459H0.329247L5.8071 7.66994L0.0302734 -0.145813H4.867L8.20553 4.41996L12.0689 -0.145813ZM11.2451 12.6961H12.544L4.15943 1.22839H2.76422L11.2451 12.6961Z"
                fill="#F9EADC"
              />
            </g>
            <defs>
              <clipPath id="clip0_652_3211">
                <rect width="15.2727" height="14" fill="white" transform="translate(0.181641)" />
              </clipPath>
            </defs>
          </svg>
        </TwitterShareButton>
        <EmailShareButton url={url}>
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_652_3223)">
              <path
                d="M11.1254 0.583252H3.32999C2.59187 0.584178 1.88424 0.891766 1.36232 1.43855C0.84039 1.98533 0.546783 2.72666 0.545898 3.49992L0.545898 10.4999C0.546783 11.2732 0.84039 12.0145 1.36232 12.5613C1.88424 13.1081 2.59187 13.4157 3.32999 13.4166H11.1254C11.8636 13.4157 12.5712 13.1081 13.0931 12.5613C13.615 12.0145 13.9087 11.2732 13.9095 10.4999V3.49992C13.9087 2.72666 13.615 1.98533 13.0931 1.43855C12.5712 0.891766 11.8636 0.584178 11.1254 0.583252ZM3.32999 1.74992H11.1254C11.4589 1.75061 11.7844 1.8558 12.0603 2.05197C12.3362 2.24814 12.5497 2.5263 12.6734 2.85067L8.40928 7.31842C8.09548 7.64585 7.67062 7.82969 7.22772 7.82969C6.78481 7.82969 6.35996 7.64585 6.04615 7.31842L1.78203 2.85067C1.90573 2.5263 2.11925 2.24814 2.39512 2.05197C2.67098 1.8558 2.99657 1.75061 3.32999 1.74992ZM11.1254 12.2499H3.32999C2.88696 12.2499 2.46207 12.0655 2.1488 11.7374C1.83553 11.4092 1.65953 10.964 1.65953 10.4999V4.37492L5.25881 8.14325C5.78145 8.6894 6.48952 8.99611 7.22772 8.99611C7.96591 8.99611 8.67398 8.6894 9.19663 8.14325L12.7959 4.37492V10.4999C12.7959 10.964 12.6199 11.4092 12.3066 11.7374C11.9934 12.0655 11.5685 12.2499 11.1254 12.2499Z"
                fill="#F9EADC"
              />
            </g>
            <defs>
              <clipPath id="clip0_652_3223">
                <rect width="13.3636" height="14" fill="white" transform="translate(0.545898)" />
              </clipPath>
            </defs>
          </svg>
        </EmailShareButton>
        <WhatsappShareButton url={url}>
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.9598 2.03704C11.3148 1.38879 10.5466 0.874806 9.69985 0.525076C8.85313 0.175345 7.94491 -0.00313607 7.02814 4.16986e-05C3.18693 4.16986e-05 0.0562815 3.11503 0.0562815 6.93702C0.0562815 8.16202 0.3799 9.35201 0.984925 10.402L0 14L3.69347 13.034C4.71357 13.587 5.8603 13.881 7.02814 13.881C10.8693 13.881 14 10.766 14 6.94402C14 5.08903 13.2754 3.34603 11.9598 2.03704ZM7.02814 12.705C5.98693 12.705 4.96683 12.425 4.07337 11.9L3.86231 11.774L1.66734 12.348L2.25126 10.22L2.11055 10.003C1.53208 9.08389 1.22492 8.02149 1.22412 6.93702C1.22412 3.75903 3.82714 1.16904 7.0211 1.16904C8.56884 1.16904 10.0251 1.77104 11.1156 2.86303C11.6555 3.39781 12.0834 4.03389 12.3744 4.73443C12.6655 5.43496 12.8139 6.186 12.8111 6.94402C12.8251 10.122 10.2221 12.705 7.02814 12.705ZM10.208 8.39302C10.0322 8.30902 9.17387 7.88902 9.01909 7.82602C8.85729 7.77002 8.74472 7.74202 8.62512 7.91002C8.50553 8.08502 8.17487 8.47702 8.07638 8.58902C7.97789 8.70802 7.87236 8.72202 7.69648 8.63102C7.5206 8.54702 6.95779 8.35802 6.29648 7.77002C5.77588 7.30802 5.43116 6.74102 5.32563 6.56602C5.22714 6.39102 5.31156 6.30002 5.40301 6.20902C5.4804 6.13202 5.57889 6.00602 5.66332 5.90802C5.74774 5.81002 5.78291 5.73302 5.8392 5.62102C5.89548 5.50202 5.86734 5.40403 5.82513 5.32003C5.78291 5.23603 5.43116 4.38203 5.29045 4.03203C5.14975 3.69603 5.00201 3.73803 4.89648 3.73103H4.55879C4.4392 3.73103 4.25628 3.77303 4.09447 3.94803C3.9397 4.12303 3.48945 4.54303 3.48945 5.39703C3.48945 6.25102 4.11558 7.07702 4.2 7.18902C4.28442 7.30802 5.43116 9.05801 7.17588 9.80701C7.59095 9.98901 7.91457 10.094 8.16784 10.171C8.58291 10.304 8.96281 10.283 9.26533 10.241C9.60301 10.192 10.2995 9.82101 10.4402 9.41501C10.5879 9.00901 10.5879 8.66602 10.5387 8.58902C10.4894 8.51202 10.3839 8.47702 10.208 8.39302Z"
              fill="#F9EADC"
            />
          </svg>
        </WhatsappShareButton>
      </div>
    </div>
  )
}
