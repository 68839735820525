import { useLosseBlogBink } from '@ubo/losse-sjedel'
import clsx from 'clsx'

export default function Pagination() {
  const { hasPrevPage, hasNextPage, prevPage, nextPage } = useLosseBlogBink()

  return (
    <div className="flex items-center">
      <svg
        className={clsx(!hasPrevPage && 'opacity-50 pointer-events-none', 'relative z-10 cursor-pointer')}
        onClick={prevPage}
        id="customprevquotebutton"
        width="42"
        height="24"
        viewBox="0 0 42 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="Arrow 2"
          d="M0.939339 13.0607C0.353554 12.4749 0.353554 11.5251 0.939339 10.9393L10.4853 1.3934C11.0711 0.807611 12.0208 0.807611 12.6066 1.3934C13.1924 1.97919 13.1924 2.92893 12.6066 3.51472L4.12132 12L12.6066 20.4853C13.1924 21.0711 13.1924 22.0208 12.6066 22.6066C12.0208 23.1924 11.0711 23.1924 10.4853 22.6066L0.939339 13.0607ZM42 13.5H2V10.5H42V13.5Z"
          fill="#F0831F"
        />
      </svg>
      <div className="bg-white -ml-5 rounded-full w-10 relative z-20 h-10" />
      <svg
        id="customnextquotebutton"
        className={clsx(!hasNextPage && 'opacity-50 pointer-events-none', '-ml-5 relative z-30 cursor-pointer')}
        width="42"
        height="24"
        viewBox="0 0 42 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={nextPage}
      >
        <path
          id="Arrow 1"
          d="M41.0607 13.0607C41.6464 12.4749 41.6464 11.5251 41.0607 10.9393L31.5147 1.3934C30.9289 0.807611 29.9792 0.807611 29.3934 1.3934C28.8076 1.97919 28.8076 2.92893 29.3934 3.51472L37.8787 12L29.3934 20.4853C28.8076 21.0711 28.8076 22.0208 29.3934 22.6066C29.9792 23.1924 30.9289 23.1924 31.5147 22.6066L41.0607 13.0607ZM0 13.5H40V10.5H0V13.5Z"
          fill="#F0831F"
        />
      </svg>
    </div>
  )
}
