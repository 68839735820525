import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import ContentDefault from './ContentDefault'
import ContentBigImage from './ContentBigImage'
import ContentUsp from './ContentUsp'
import ContentUspAlternative from './ContentUspAlternative'
import ContentQuote from './ContentQuote'
import ContentImage from './ContentImage'
import ContentPros from './ContentPros'
import ContentBlockContent from './ContentBlockContent'
import ContentSteps from './ContentSteps'
import ContentUspHover from './ContentUspHover'
import ContentBlockTitle from './ContentBlockTitle'
import ContentTypeForm from './ContentTypeForm'
import ContentVideo from './ContentVideo'

export default function ContentShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    default: ContentDefault,
    bigimage: ContentBigImage,
    usp: ContentUsp,
    uspalternative: ContentUspAlternative,
    quote: ContentQuote,
    image: ContentImage,
    video: ContentVideo,
    pros: ContentPros,
    blockcontent: ContentBlockContent,
    blocktitle: ContentBlockTitle,
    steps: ContentSteps,
    usphover: ContentUspHover,
    typeform: ContentTypeForm
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
