import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { useRef, useState } from 'react'
import { Navigation } from 'swiper/modules'
import Content from '~/components/elements/Content'
import Slider, { Slide } from '~/components/elements/Slider'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'
import { useInView } from 'framer-motion'

export default function ContentPros({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const [currentItem, setCurrentItem] = useState(0)

  const containerRef = useRef(null)
  const desktopCardRef = useRef(null)
  const mobileCardRef = useRef(null)

  const containerVisible = useInView(containerRef, { once: true })
  const desktopCardVisible = useInView(desktopCardRef, { once: true })
  const mobileCardVisible = useInView(mobileCardRef, { once: true })

  return (
    <section data-component="ContentPros" className="section">
      <div
        ref={containerRef}
        className={clsx(
          containerVisible && 'animate-fade-up animate-delay-100 animate-duration-500 animate-ease-in-out',
          containerVisible ? 'opacity-100' : 'opacity-0',
          'container'
        )}
      >
        <div className="lg:bg-site-secondary py-7 lg:py-14 xl:py-[76px] flex flex-col gap-8 xl:gap-16 lg:px-16 xl:px-24 rounded-[25px] sm:rounded-[50px]">
          <div className="flex flex-col gap-4 lg:gap-8">
            <h2 className="text-3xl sm:text-4xl lg:text-center xl:text-5xl text-site-nav lg:text-white font-bold">{fields.title}</h2>
            <Content className="content max-lg:children-p:!text-left max-w-screen-md mx-auto">{fields.description}</Content>
          </div>
          <div className="hidden lg:grid grid-cols-2 gap-8 xl:gap-28">
            <div className="flex flex-col gap-8 xl:gap-14 pt-8">
              {fields?.items?.map((item, index) => (
                <div key={index}>
                  <div onClick={() => setCurrentItem(index)} className="group flex cursor-pointer justify-between items-center">
                    <span className="text-lg text-site-nav font-bold group-hover:underline">{item?.title}</span>
                    <svg
                      className={clsx(currentItem === index && 'rotate-180 smooth')}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="fi-rr-angle-small-down">
                        <path
                          id="Vector"
                          d="M18.7098 8.2101C18.6169 8.11638 18.5063 8.04198 18.3844 7.99121C18.2625 7.94044 18.1318 7.91431 17.9998 7.91431C17.8678 7.91431 17.7371 7.94044 17.6152 7.99121C17.4934 8.04198 17.3828 8.11638 17.2898 8.2101L12.7098 12.7901C12.6169 12.8838 12.5063 12.9582 12.3844 13.009C12.2625 13.0598 12.1318 13.0859 11.9998 13.0859C11.8678 13.0859 11.7371 13.0598 11.6152 13.009C11.4934 12.9582 11.3828 12.8838 11.2898 12.7901L6.70982 8.2101C6.61685 8.11638 6.50625 8.04198 6.38439 7.99121C6.26253 7.94044 6.13183 7.91431 5.99982 7.91431C5.8678 7.91431 5.7371 7.94044 5.61524 7.99121C5.49338 8.04198 5.38278 8.11638 5.28982 8.2101C5.10356 8.39747 4.99902 8.65092 4.99902 8.9151C4.99902 9.17929 5.10356 9.43274 5.28982 9.6201L9.87982 14.2101C10.4423 14.7719 11.2048 15.0875 11.9998 15.0875C12.7948 15.0875 13.5573 14.7719 14.1198 14.2101L18.7098 9.6201C18.8961 9.43274 19.0006 9.17929 19.0006 8.9151C19.0006 8.65092 18.8961 8.39747 18.7098 8.2101Z"
                          fill="#2F6F7D"
                        />
                      </g>
                    </svg>
                  </div>
                  {currentItem === index && (
                    <div className="pt-4 animate-fade-up animate-duration-300 animate-ease-in-out">
                      <Content className="content">{item?.description}</Content>
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="flex col-span-1 justify-center">
              <div
                ref={desktopCardRef}
                className={clsx(
                  desktopCardVisible && 'animate-fade-up animate-delay-300 animate-duration-500 animate-ease-in-out',
                  desktopCardVisible ? 'opacity-100' : 'opacity-0',
                  'bg-site-base h-[280px] xl:h-[360px] pt-10 px-10 w-full flex justify-center items-end rounded-[12px] sm:rounded-[25px]'
                )}
              >
                <LossePlaatjie
                  className="animate-fade-left animate-delay-300 animate-duration-300 animate-ease-in-out"
                  key={`${currentItem}-image`}
                  maxWidth={450}
                  src={fields?.items?.[currentItem]?.image}
                />
              </div>
            </div>
          </div>
          <div className="lg:hidden block relative">
            <div className="h-10 absolute z-10 bottom-2 right-2 flex max-lg:justify-end">
              <div className="flex items-center">
                <svg
                  className="cursor-pointer"
                  id="customprevpros"
                  width="42"
                  height="24"
                  viewBox="0 0 42 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    id="Arrow 2"
                    d="M0.939339 13.0607C0.353554 12.4749 0.353554 11.5251 0.939339 10.9393L10.4853 1.3934C11.0711 0.807611 12.0208 0.807611 12.6066 1.3934C13.1924 1.97919 13.1924 2.92893 12.6066 3.51472L4.12132 12L12.6066 20.4853C13.1924 21.0711 13.1924 22.0208 12.6066 22.6066C12.0208 23.1924 11.0711 23.1924 10.4853 22.6066L0.939339 13.0607ZM42 13.5H2V10.5H42V13.5Z"
                    fill="#F0831F"
                  />
                </svg>
                <div className="bg-site-secondary -ml-5 rounded-full w-10 h-10" />
                <svg
                  id="customnextpros"
                  className="-ml-5 cursor-pointer"
                  width="42"
                  height="24"
                  viewBox="0 0 42 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    id="Arrow 1"
                    d="M41.0607 13.0607C41.6464 12.4749 41.6464 11.5251 41.0607 10.9393L31.5147 1.3934C30.9289 0.807611 29.9792 0.807611 29.3934 1.3934C28.8076 1.97919 28.8076 2.92893 29.3934 3.51472L37.8787 12L29.3934 20.4853C28.8076 21.0711 28.8076 22.0208 29.3934 22.6066C29.9792 23.1924 30.9289 23.1924 31.5147 22.6066L41.0607 13.0607ZM0 13.5H40V10.5H0V13.5Z"
                    fill="#F0831F"
                  />
                </svg>
              </div>
            </div>
            <div
              className={clsx(
                mobileCardVisible && 'animate-fade-up animate-delay-300 animate-duration-500 animate-ease-in-out',
                mobileCardVisible ? 'opacity-100' : 'opacity-0',
                'rounded-[12px] sm:rounded-[25px] overflow-hidden relative'
              )}
              ref={mobileCardRef}
            >
              <Slider
                navigation={{
                  prevEl: '#customprevpros',
                  nextEl: '#customnextpros'
                }}
                modules={[Navigation]}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 15
                  }
                }}
                className="h-full w-full"
              >
                {fields?.items?.map((item, index) => (
                  <Slide className="bg-site-base rounded-[12px] pt-4 sm:pt-10 px-6 sm:px-10 sm:rounded-[25px]" key={index}>
                    <div>
                      <h2 className="text-xl pb-3 text-white font-bold">{item?.title}</h2>

                      <Content className="content white">{item?.description}</Content>
                    </div>
                    <div className="pr-16 mt-4 sm:mt-8">
                      <LossePlaatjie maxWidth={1000} src={item?.image} />
                    </div>
                  </Slide>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
