import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import Button from '~/components/elements/Button'
import type { Post as Props } from '~/graphql/types'

export default function Post({ data, readmore }: { data: Props; readmore?: string }) {
  if (!data.title) return null

  return (
    <LosseLink to={data.uri} className="group">
      <div className="w-full relative">
        <div className="bg-site-base absolute top-7 left-6 rounded-full py-2 px-5 text-white text-sm font-light">
          {data?.categories?.edges?.[0]?.node?.name}
        </div>
        <LossePlaatjie
          src={data?.featuredImage?.node}
          className="w-full h-[300px] sm:h-[415px] rounded-[25px] sm:rounded-[50px] object-cover"
          placeholder="blur"
          maxwidth={600}
          title={data?.title}
        />
        <div className="absolute px-3 py-7 sm:p-10 z-20 w-full bottom-0 left-0">
          <div className="absolute w-full h-full left-0 bottom-0 backdrop-blur-[5px] rounded-b-[25px] sm:rounded-b-[50px] bg-blog-gradient" />
          <h2 className="text-site-nav relative z-10 text-sm sm:text-xl font-bold group-hover:underline">{data?.title}</h2>
          <Button className="absolute -bottom-5 right-4 sm:right-8 z-10">{readmore || 'Lees meer'}</Button>
        </div>
      </div>
    </LosseLink>
  )
}
