import { LossePlaatjie } from '@ubo/losse-sjedel'
import type { Auteur } from '~/graphql/types'

export default function Author({ author }: { author: Auteur }) {
  return (
    <div className="flex items-center gap-3">
      <LossePlaatjie maxWidth={500} className="w-[45px] md:w-[65px]" src={author.featuredImage.node} />
      <div className="flex flex-col text-white text-sm sm:text-base">
        <span className="font-bold">{author.title}</span>
        <span className="font-light">{author.recap.function}</span>
      </div>
    </div>
  )
}
