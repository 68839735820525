import { LosseLink, useLoaderData } from '@ubo/losse-sjedel'
import type { HeaderLoaderData } from './Header'
import clsx from 'clsx'
import { useCustomLayout } from '~/hooks/useCustomLayout'
import { type Acf_Link } from '~/graphql/types'

export default function Menu() {
  const { header } = useLoaderData<HeaderLoaderData>()
  const { desktopMenuOpen } = useCustomLayout()

  return (
    <div>
      <div
        id="stickyTopMenu"
        className={clsx(desktopMenuOpen ? 'flex-col gap-5 md:gap-6' : 'flex-row gap-8 xl:gap-14', 'flex  justify-end')}
      >
        {desktopMenuOpen && (
          <MenuItem
            key={'-1'}
            link={{
              url: '/#',
              title: 'Home',
              target: '_self'
            }}
          />
        )}
        {header?.menuHeader?.map((edge, index) => {
          return <MenuItem key={index} link={edge.link} />
        })}
      </div>
    </div>
  )
}

function MenuItem({ link }: { link: Acf_Link }) {
  const { desktopMenuOpen } = useCustomLayout()

  return (
    <li className="relative">
      <div className="flex flex-wrap items-center">
        <LosseLink
          className={clsx(
            desktopMenuOpen && 'flex items-center',
            'text-xl group font-bold text-site-nav whitespace-nowrap btn--pseudo lg:text-base lg:font-bold px-2'
          )}
          to={link.url}
          target={link.target}
        >
          <div
            className={clsx(
              desktopMenuOpen
                ? 'relative hidden group-aria-current-page:!block'
                : 'absolute left-0 -top-2 group-hover:opacity-100 opacity-0',
              ' smooth z-10'
            )}
          >
            <div className={clsx(desktopMenuOpen ? 'w-10 mr-5 h-10' : 'w-5 h-5', 'bg-site-alternative rounded-full')} />
            <svg
              className="absolute top-0 bottom-0 my-auto left-3"
              width={desktopMenuOpen ? '42' : '21'}
              height={desktopMenuOpen ? '24' : '12'}
              viewBox="0 0 42 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M41.0607 13.0607C41.6464 12.4749 41.6464 11.5251 41.0607 10.9393L31.5147 1.3934C30.9289 0.807611 29.9792 0.807611 29.3934 1.3934C28.8076 1.97919 28.8076 2.92893 29.3934 3.51472L37.8787 12L29.3934 20.4853C28.8076 21.0711 28.8076 22.0208 29.3934 22.6066C29.9792 23.1924 30.9289 23.1924 31.5147 22.6066L41.0607 13.0607ZM0 13.5H40V10.5H0V13.5Z"
                fill="#F0831F"
              />
            </svg>
          </div>
          <div
            className={clsx(
              desktopMenuOpen ? 'text-2xl md:text-4xl smooth group-hover:text-site-secondary text-site-base' : 'text-base',
              'relative z-20'
            )}
          >
            {link.title}
          </div>
        </LosseLink>
      </div>
    </li>
  )
}
