import clsx from 'clsx'
import { useInView } from 'framer-motion'
import { useRef } from 'react'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentDefault({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const containerRef = useRef(null)

  const containerVisible = useInView(containerRef, { once: true })

  return (
    <section data-component="ContentDefault" className="section">
      <div
        ref={containerRef}
        className={clsx(
          containerVisible && 'animate-fade-up animate-delay-100 animate-duration-500 animate-ease-in-out',
          containerVisible ? 'opacity-100' : 'opacity-0',
          'container'
        )}
      >
        <div className="max-w-screen-lg mx-auto">
          <h2
            className={clsx(
              fields?.smallertitles ? 'text-2xl sm:text-2xl xl:text-3xl' : 'text-3xl sm:text-4xl xl:text-5xl',
              'text-site-nav lg:text-center !leading-[1.2] font-bold pb-6 lg:pb-8'
            )}
          >
            {fields?.title}
          </h2>

          {fields.description && <Content className="content max-lg:children-p:!text-left">{fields?.description}</Content>}

          {fields?.links && (
            <div className="flex items-center gap-2 mt-4">
              {fields.links?.map((link, index) => (
                <Button
                  className={clsx(index === 2 && 'btn--outline', index === 1 && 'btn--alt', 'btn')}
                  key={index}
                  to={link?.link?.url || '/'}
                  target={link?.link?.target}
                >
                  {link?.link?.title}
                </Button>
              ))}
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
