import clsx from 'clsx'
import { useInView } from 'framer-motion'
import { useRef } from 'react'
import Circle from '~/components/elements/Circle'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentBlockTitle({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const containerRef = useRef(null)

  const containerVisible = useInView(containerRef, { once: true })

  return (
    <section data-component="ContentBlockTitle" className="section">
      <div
        ref={containerRef}
        className={clsx(
          containerVisible && 'animate-fade-up animate-delay-200 animate-duration-500 animate-ease-in-out',
          containerVisible ? 'opacity-100' : 'opacity-0',
          'container'
        )}
      >
        <div className="max-w-screen-lg mx-auto relative bg-site-secondary rounded-[25px] sm:rounded-[50px] p-6 sm:p-10 md:px-20 md:py-14">
          <h2 className="text-xl text-center sm:text-2xl xl:text-3xl font-bold text-white">{fields?.title}</h2>
          <Circle color="#F2DFCE" className="absolute -bottom-10 -right-10 -z-10 w-[164px]" />
        </div>
      </div>
    </section>
  )
}
