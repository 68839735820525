import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { useRef, useState } from 'react'
import Button from '~/components/elements/Button'
import Circle from '~/components/elements/Circle'
import Content from '~/components/elements/Content'
import Form from '~/components/elements/Form'
import Loading from '~/components/elements/Loading'
import type { Page_Flexcontent_Flex_Form } from '~/graphql/types'
import { useInView } from 'framer-motion'

export default function FormDownload({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  const [open, setOpen] = useState(false)

  const containerRef = useRef(null)

  const containerVisible = useInView(containerRef, { once: true })

  return (
    <section data-component="FormDownload" className="section">
      <div
        ref={containerRef}
        className={clsx(
          containerVisible && 'animate-fade-up animate-delay-100 animate-duration-500 animate-ease-in-out',
          containerVisible ? 'opacity-100' : 'opacity-0',
          'container lg:py-10 max-sm:mt-[100px]'
        )}
      >
        <div className="relative max-w-screen-lg mx-auto">
          <Circle color="#F2DFCE" className="absolute w-[309px] -left-36 -top-20" />
          <div className="bg-site-secondary relative z-10 max-lg:pt-36 rounded-[25px] sm:rounded-[50px] p-6 sm:p-12 min-h-[312px]">
            <LossePlaatjie
              maxWidth={1000}
              className="absolute w-[300px] object-contain right-0 max-sm:left-0 max-sm:mx-auto sm:-right-20 h-auto sm:h-[120%] -top-[30%] sm:-top-[10%] max-sm:max-w-[170px]"
              src={fields?.image}
            />
            {open ? (
              <div className="sm:w-[50%] lg:w-[80%]">
                <h2 className="text-white font-bold text-3xl sm:text-4xl xl:text-5xl pb-3 sm:pb-8">Download</h2>
                <Form
                  data={fields?.form}
                  onSubmitDone={() => {
                    window.open(fields?.file?.mediaItemUrl, '_blank')
                  }}
                  scrollToConfirm={false}
                  renderLoading={() => <Loading />}
                  renderStatus={({ content }) => <Content className="content">{content}</Content>}
                />
              </div>
            ) : (
              <div className="flex flex-col gap-6 sm:gap-10">
                <h2 className="text-white font-bold text-3xl sm:text-4xl xl:text-5xl">{fields?.title}</h2>
                <h3 className="text-site-nav font-light text-xl sm:text-2xl xl:text-3xl">{fields?.subtitle}</h3>
                <div>
                  <Button onClick={() => setOpen(true)}>{fields?.label}</Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
