import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { useRef } from 'react'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'
import { useInView } from 'framer-motion'

export default function ContentImage({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const imageRef = useRef(null)
  const contentRef = useRef(null)

  const imageVisible = useInView(imageRef, { once: true })
  const contentVisible = useInView(contentRef, { once: true })

  return (
    <section id={fields?.anchorId && fields?.anchorId} data-component="ContentImage" className="section">
      <div className="container">
        <div className="grid grid-cols-1 lg:grid-cols-7 gap-7 lg:gap-20">
          {fields?.imageposition === 'left' && (
            <div
              ref={imageRef}
              className={clsx(
                imageVisible && 'animate-fade-up animate-delay-300 animate-duration-500 animate-ease-in-out',
                imageVisible ? 'opacity-100' : 'opacity-0',
                'col-span-1 max-lg:-order-1 lg:col-span-3 flex max-lg:justify-center items-center'
              )}
            >
              <LossePlaatjie maxWidth={1000} className="max-lg:max-w-[300px] max-lg:mx-auto" src={fields?.image} />
            </div>
          )}

          <div
            ref={contentRef}
            className={clsx(
              contentVisible && 'animate-fade-up animate-delay-100 animate-duration-500 animate-ease-in-out',
              contentVisible ? 'opacity-100' : 'opacity-0',
              'col-span-1 max-lg:row-start-1 max-lg:order-1 lg:col-span-4 flex flex-col justify-center lg:pl-10'
            )}
          >
            <h2
              className={clsx(
                fields?.smallertitles ? 'text-2xl sm:text-2xl xl:text-3xl' : 'text-3xl sm:text-4xl xl:text-5xl',
                'text-site-nav !leading-[1.2] font-bold pb-6 lg:pb-8'
              )}
            >
              {fields?.title}
            </h2>
            <Content className="content">{fields?.description}</Content>
            {fields?.links && (
              <div className="flex items-center gap-2 mt-4 lg:mt-10">
                {fields.links?.map((link, index) => (
                  <Button
                    className={clsx(index === 2 && 'btn--outline', index === 1 && 'btn--alt', 'btn')}
                    key={index}
                    to={link?.link?.url || '/'}
                    target={link?.link?.target}
                  >
                    {link?.link?.title}
                  </Button>
                ))}
              </div>
            )}
          </div>

          {fields?.imageposition === 'right' && (
            <div
              ref={imageRef}
              className={clsx(
                imageVisible && 'animate-fade-up animate-delay-300 animate-duration-500 animate-ease-in-out',
                imageVisible ? 'opacity-100' : 'opacity-0',
                'col-span-1 lg:col-span-3 flex max-lg:justify-center items-center'
              )}
            >
              <LossePlaatjie maxWidth={1000} className="max-lg:max-w-[300px] max-lg:mx-auto" src={fields?.image} />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
