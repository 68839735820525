import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import PostsHighlighted from './PostsHighlighted'
import PostsBlog from './PostsBlog'
import FaqOverview from './FaqOverview'
import FaqHighlighted from './FaqHighlighted'
import PostsLatest from './PostsLatest'
import NewsOverview from './NewsOverview'
import NewsOther from './NewsOther'

export default function PostsShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    highlighted: PostsHighlighted,
    overview: PostsBlog,
    faqoverview: FaqOverview,
    faqhighlighted: FaqHighlighted,
    newslatest: PostsLatest,
    newsoverview: NewsOverview,
    newsother: NewsOther
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
