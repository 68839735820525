import { LossePlaatjie } from '@ubo/losse-sjedel'
import Breadcrumbs from '~/components/elements/Breadcrumbs'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'

export default function BannerWithLink({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  return (
    <section data-component="BannerWithLink" className="section">
      <div className="container">
        <div className="bg-site-base rounded-[25px] animate-fade-up animate-duration-500 animate-ease-in-out sm:rounded-[50px] py-6 sm:py-10 px-4 xs:px-6 sm:px-10 xl:px-14">
          <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-10 xl:gap-20">
            <div className="sm:pb-5 xl:pb-10">
              <div>
                <Breadcrumbs />
              </div>
              <div className="xs:pl-3 sm:pl-5 xl:pl-10 pt-6 xl:pt-11">
                <h1 className="title pb-5 !text-white">{fields?.title}</h1>
                <Content className="content content--table white">{fields?.description}</Content>
              </div>
            </div>

            <div className="flex justify-center items-center">
              <div className="bg-site-secondary max-w-[440px] animate-fade-up animate-delay-100 animate-duration-500 animate-ease-in-out flex gap-4 xs:gap-6 flex-col items-center rounded-[12px] lg:rounded-[25px] px-4 xs:px-10 xl:px-16 py-6 sm:py-9">
                <LossePlaatjie loading="eager" maxWidth={66} src={fields?.image} />
                <h2 className="text-white text-center text-xl sm:text-2xl font-bold">{fields?.subtitle}</h2>
                {fields?.links && (
                  <Button className="btn" to={fields?.links?.[0]?.link?.url} target={fields?.links?.[0]?.link?.target}>
                    {fields?.links?.[0]?.link?.title}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
