import useGeneralInfo from '~/hooks/useGeneralInfo'
import Form from '../elements/Form'
import Loading from '../elements/Loading'
import { LossePlaatjie } from '@ubo/losse-sjedel'
import { useState, useEffect, useRef } from 'react'

import clsx from 'clsx'
import Content from '../elements/Content'
import { useCustomLayout } from '~/hooks/useCustomLayout'

export default function FormSticky() {
  const { buttonIcon, buttonText, form, formTitle } = useGeneralInfo()
  const [stickyFormOpen, setStickyFormOpen] = useState<boolean>()
  const { desktopMenuOpen } = useCustomLayout()
  const [formStatus, setFormStatus] = useState('')
  const formRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (formRef.current && !formRef.current.contains(event.target as Node)) {
        setStickyFormOpen(false)
      }
    }

    function handleEscapeKey(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        setStickyFormOpen(false)
      }
    }

    document.addEventListener('click', handleClickOutside)
    document.addEventListener('keydown', handleEscapeKey)

    return () => {
      document.removeEventListener('click', handleClickOutside)
      document.removeEventListener('keydown', handleEscapeKey)
    }
  }, [])

  useEffect(() => {
    if (stickyFormOpen) {
      document.documentElement.classList.add('overflow-hidden')
    } else {
      document.documentElement.classList.remove('overflow-hidden')
    }
  }, [stickyFormOpen])

  return (
    <>
      {stickyFormOpen && <div className="fixed top-0 left-0 w-screen h-screen bg-site-base-700/90 mix-blend-multiply z-[99]" />}
      <div
        data-component="FormSticky"
        className={clsx(stickyFormOpen ? 'translate-x-0' : 'translate-x-full', 'fixed top-[30vh] right-0 transition-transform z-[100]')}
      >
        <div className="relative" ref={formRef}>
          <div
            className={clsx(
              desktopMenuOpen ? 'block' : 'hidden sm:block',
              'absolute top-[57px] sm:top-[65px] -left-[100px] sm:-left-[116px] -rotate-90'
            )}
          >
            <button
              onClick={() => setStickyFormOpen(!stickyFormOpen)}
              className="group items-center bg-site-conversion text-white rounded-t-[25px] pl-3 pr-6 sm:pl-6 sm:pr-9 py-3 sm:py-4 flex"
            >
              <LossePlaatjie maxWidth={500} src={buttonIcon} className="mr-3" />
              <span className="font-bold text-sm lg:group-hover:underline">{buttonText}</span>
            </button>
          </div>

          <div className="max-w-[331px] min-h-[250px] bg-site-secondary p-8 rounded-bl-[3rem]">
            {formStatus !== 'done' && <span className="text-xl font-bold text-site-nav mb-4 inline-block">{formTitle}</span>}
            <Form
              className="form-sticky"
              data={form}
              renderLoading={() => <Loading />}
              renderStatus={({ status, content }) => {
                setFormStatus(status)

                return <Content className="content">{content}</Content>
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}
