import Content from '~/components/elements/Content'
import type { Acf_Link, Page_Flexcontent_Flex_Form } from '~/graphql/types'
import Breadcrumbs from '~/components/elements/Breadcrumbs'
import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import Form from '~/components/elements/Form'
import Loading from '~/components/elements/Loading'
import type { ReactNode } from 'react'
import { useState } from 'react'
import { FormDuufField, FormDuufGroup, FormDuufSubmit } from '@ubo/form-duuf'
import useGeneralInfo from '~/hooks/useGeneralInfo'
import { EmailIcon, FacebookIcon, InstagramIcon, LinkedinIcon, LocationIcon, PhoneIcon, XIcon } from '~/components/elements/Icons'

export default function FormContact({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  const { address, email, phone, instagram, facebook, linkedin, x } = useGeneralInfo()
  const [formStatus, setFormStatus] = useState('')

  return (
    <section data-component="FormContact" className="section">
      <div className="container">
        <div className="bg-site-base rounded-[25px] animate-fade-up animate-duration-500 animate-ease-in-out sm:rounded-[50px] pt-6 pb-0 lg:py-10 lg:px-14">
          <div className="px-4 xs:px-6 sm:px-8 lg:px-0">
            <Breadcrumbs />
          </div>
          <div className="grid grid-cols-2">
            <div className="col-span-2 lg:col-span-1 sm:pb-5 xl:pb-10 mb-8 px-4 lg:px-0">
              <div className="xs:pl-3 sm:pl-5 xl:pl-10 pt-6 xl:pt-11">
                <h1 className="title pb-5 !text-white">{fields?.title}</h1>
                <div className="flex">
                  <LossePlaatjie
                    maxWidth={500}
                    className="animate-fade-up animate-duration-500 animate-ease-in-out animate-delay-100 min-w-[87px] min-h-[87px] w-[87px] h-[87px] object-cover mr-8"
                    src={fields?.image}
                  />
                  <Content className="content white lg:pr-8">{fields?.description}</Content>
                </div>
              </div>
            </div>
            <div className="relative col-span-2 lg:col-span-1">
              <div className="relative lg:absolute bg-site-secondary rounded-[25px] lg:top-[50px] p-4 md:p-8 lg:p-12 min-h-[380px] min-w-full">
                {formStatus !== 'done' && <h2 className="text-white font-bold text-2xl mb-8">{fields?.formtitle}</h2>}
                <Form
                  className="form-contact"
                  scrollToConfirm={false}
                  generate={false}
                  data={fields.form}
                  privacyUrl="/privacy-policy/"
                  privacyUrlPlacement="Privacy"
                  renderLoading={() => <Loading />}
                  renderStatus={({ status, content }) => {
                    setFormStatus(status)

                    return <Content className="content children-headings:text-white">{content}</Content>
                  }}
                >
                  <>
                    <FormDuufGroup hideIds={[8]} />
                    <div className="flex flex-col sm:flex-row items-start mt-9">
                      <div className="pr-6">
                        <FormDuufField id={8} />
                      </div>
                      <div className="flex justify-end w-full">
                        <FormDuufSubmit />
                      </div>
                    </div>
                  </>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 px-4 py-12 lg:p-20">
          <div className="col-span-2 lg:col-span-1">
            <ul>
              <ListItem url={address?.url} target={address?.target} title={address?.title} icon={<LocationIcon />} />
              <ListItem url={email?.url} target={email?.target} title={email?.title} icon={<EmailIcon />} />
              <ListItem url={phone?.url} target={phone?.target} title={phone?.title} icon={<PhoneIcon />} />
            </ul>
            <div className="flex mt-20">
              <span>Volg ons op:</span>
              <ul className="flex ml-9">
                <ListItem icon={<InstagramIcon />} url={instagram?.url} />
                <ListItem icon={<FacebookIcon />} url={facebook?.url} />
                <ListItem icon={<LinkedinIcon />} url={linkedin?.url} />
                <ListItem icon={<XIcon />} url={x?.url} />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

interface TListItem extends Acf_Link {
  icon?: ReactNode
}

const ListItem = ({ url, target, title, icon }: TListItem) => {
  return (
    <li className="mb-7">
      <LosseLink to={url} target={target} className="group flex items-start">
        <div className="mr-5 hover:opacity-75 transition-opacity">{icon}</div>
        <span className="group-hover:underline">{title}</span>
      </LosseLink>
    </li>
  )
}
