import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { useRef } from 'react'
import Button from '~/components/elements/Button'
import Circle from '~/components/elements/Circle'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'
import { useInView } from 'framer-motion'

export default function ContentUsp({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const textRef = useRef(null)
  const uspRef = useRef(null)

  const textVisible = useInView(textRef, { once: true })
  const uspVisible = useInView(uspRef, { once: true })

  return (
    <section data-component="ContentUsp" className="section">
      <div className="container py-8 sm:py-20">
        <div className="grid grid-cols-1 lg:grid-cols-9 gap-8 xs:gap-10 xl:gap-20">
          <div
            ref={uspRef}
            className={clsx(
              uspVisible && 'animate-fade-up animate-delay-300 animate-duration-500 animate-ease-in-out',
              uspVisible ? 'opacity-100' : 'opacity-0',
              'relative col-span-1 lg:col-span-4 flex flex-col justify-center'
            )}
          >
            <div className="relative">
              <Circle
                color="#B0CBD3"
                className={clsx(
                  uspVisible && 'animate-fade-up animate-delay-500 animate-duration-500 animate-ease-in-out',
                  uspVisible ? 'opacity-100' : 'opacity-0',
                  'absolute w-[250px] sm:w-[343px] -left-20 -top-6 sm:-top-20'
                )}
              />
              <Circle
                color="#B0CBD3"
                className={clsx(
                  uspVisible && 'animate-fade-up animate-delay-500 animate-duration-500 animate-ease-in-out',
                  uspVisible ? 'opacity-100' : 'opacity-0',
                  'absolute w-[151px] -right-9 -bottom-0 lg:top-[310px]'
                )}
              />
              <div className="max-lg:max-w-[500px] max-lg:mx-auto flex pl-2 sm:pl-7 relative z-10 flex-row flex-wrap">
                {fields?.items?.slice(0, 2).map((item, index) => (
                  <div key={index} className="w-1/2 p-1 sm:p-2 xl:p-[10px]">
                    <div className="bg-white px-6 xl:px-8 flex gap-5 flex-col justify-center rounded-[12px] sm:rounded-[25px] h-[170px] xs:h-[190px]">
                      <div className="relative pl-4">
                        <Circle color="#F2DFCE" className="absolute -top-4 left-0 w-[54px]" />
                        <LossePlaatjie maxWidth={1000} className="relative z-10" src={item?.image} />
                      </div>
                      <span className="text-base lg:text-xl font-bold !leading-[1.1]">{item?.title}</span>
                    </div>
                  </div>
                ))}
              </div>
              <div className="max-lg:max-w-[500px] max-lg:mx-auto flex pr-2 sm:pr-7 relative z-10 flex-row flex-wrap">
                {fields?.items?.slice(2, 4).map((item, index) => (
                  <div key={index} className="w-1/2 p-1 sm:p-2 xl:p-[10px]">
                    <div className="bg-white px-6 xl:px-8 flex gap-5 flex-col justify-center rounded-[12px] sm:rounded-[25px] h-[170px] xs:h-[190px]">
                      <div className="relative pl-4">
                        <Circle color="#F2DFCE" className="absolute -top-4 left-0 w-[54px]" />
                        <LossePlaatjie maxWidth={1000} className="relative z-10" src={item?.image} />
                      </div>
                      <span className="text-base lg:text-xl font-bold !leading-[1.1]">{item?.title}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div
            ref={textRef}
            className={clsx(
              textVisible && 'animate-fade-up animate-delay-100 animate-duration-500 animate-ease-in-out',
              textVisible ? 'opacity-100' : 'opacity-0',
              'col-span-1 lg:col-span-5'
            )}
          >
            <h2 className="text-3xl sm:text-4xl text-site-nav xl:text-5xl !leading-[1.2] font-bold pb-4 sm:pb-8">{fields?.title}</h2>
            <Content className="content">{fields?.description}</Content>
            {fields?.links && (
              <div className="flex items-center gap-2 mt-4 sm:mt-10">
                {fields?.links?.map((link, index) => (
                  <Button
                    className={clsx(index === 2 && 'btn--outline', index === 1 && 'btn--alt', 'btn')}
                    key={index}
                    to={link?.link?.url || '/'}
                    target={link?.link?.target}
                  >
                    {link?.link?.title}
                  </Button>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
