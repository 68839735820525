import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Breadcrumbs from '~/components/elements/Breadcrumbs'
import Button from '~/components/elements/Button'
import Circle from '~/components/elements/Circle'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'

export default function BannerDefault({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  return (
    <section data-component="BannerDefault" className="section">
      <div className="container">
        <div className="bg-site-base rounded-[25px] animate-fade-up animate-duration-500 animate-ease-in-out sm:rounded-[50px] py-6 sm:py-10 px-4 xs:px-6 sm:px-10 xl:px-14">
          <div className="grid grid-cols-1 gap-8 lg:grid-cols-5 lg:gap-10 xl:gap-20">
            <div className={clsx(fields?.isicon || !fields?.imageoutside ? 'col-span-3' : 'col-span-2', 'sm:pb-5 xl:pb-10')}>
              <div>
                <Breadcrumbs />
              </div>
              <div className="xs:pl-3 sm:pl-5 xl:pl-10 pt-6 xl:pt-11">
                <h1 className="title pb-5 !text-white">{fields?.title}</h1>
                <Content className="content white">{fields?.description}</Content>
                {fields?.links && (
                  <div className="flex flex-col gap-3 mt-5 lg:gap-6 lg:mt-10">
                    {fields.links.map((link, index) => (
                      <div key={index}>
                        <Button
                          className={clsx(index === 2 && 'btn--outline', index === 1 && 'btn--outline', 'btn')}
                          to={link?.link?.url || '/'}
                          target={link?.link?.target}
                        >
                          {link?.link?.title}
                        </Button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div
              className={clsx(
                fields?.imageoutside ? 'justify-end' : 'justify-center lg:justify-end',
                fields?.isicon || !fields?.imageoutside ? 'lg:relative col-span-2' : 'relative col-span-3',
                'flex items-center '
              )}
            >
              {fields?.imageoutside && (
                <>
                  <Circle color="#B0CBD3" className="w-[40px] lg:w-[88px] absolute z-10 bottom-[20px] -right-[0px] lg:-right-[50px]" />
                  <Circle color="#B0CBD3" className="w-[100px] lg:w-[231px] absolute z-10 bottom-[80px] left-[45px]" />
                </>
              )}
              <LossePlaatjie
                loading="eager"
                maxWidth={2000}
                className={clsx(
                  fields?.imageoutside && 'w-full lg:w-[115%] z-20 relative max-w-[unset]',
                  !fields?.imageoutside && 'max-xs:max-w-[260px] xs:max-w-[330px]',
                  fields.isicon &&
                    'max-lg:absolute max-lg:w-auto -z-10 max-lg:!opacity-50 max-lg:h-[80%] max-lg:top-0 max-lg:bottom-0 max-lg:right-10 max-lg:my-auto',
                  'animate-fade-up animate-duration-500 animate-ease-in-out animate-delay-100'
                )}
                src={fields?.image}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
