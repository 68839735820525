import clsx from "clsx";
import Content from "~/components/elements/Content";
import type { Page_Flexcontent_Flex_Content } from "~/graphql/types";

export default function ContentVideo({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentVideo" className="section">
      <div className="container">
        {!fields?.description && (
          <h2 className={clsx(
            fields?.smallertitles ? 'text-2xl sm:text-2xl xl:text-3xl' : 'text-3xl sm:text-4xl xl:text-5xl',
            'text-site-nav !leading-[1.2] font-bold pb-6 lg:pb-8 text-center'
          )}>{fields?.title}</h2>
        )}

        {fields?.description ? (
          <div className="grid lg:grid-cols-2 gap-10 lg:gap-20 items-center">
            <div className="sm:col-span-1">
              <div className="relative aspect-video">
                <iframe
                  src={fields?.vimeoUrl}
                  title={fields?.title}
                  frameBorder="0"
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="inset-0 w-full h-full rounded-[25px] sm:rounded-[50px]"
                />
              </div>
            </div>
            <div className="sm:col-span-1">
              <h2 className={clsx(
                fields?.smallertitles ? 'text-2xl sm:text-2xl xl:text-3xl' : 'text-3xl sm:text-4xl xl:text-5xl',
                'text-site-nav !leading-[1.2] font-bold pb-6 lg:pb-8'
              )}>{fields?.title}</h2>
              <Content className="content">{fields?.description}</Content>
            </div>
          </div>
        ) : (
          <div className="grid lg:grid-cols-12">
            <div className="relative aspect-video sm:col-span-10 sm:col-start-2">
              <iframe
                src={fields?.vimeoUrl}
                title={fields?.title}
                frameBorder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="inset-0 w-full h-full rounded-[25px] sm:rounded-[50px]"
              />
            </div>
          </div>
        )}


      </div>
    </section>
  )
}
