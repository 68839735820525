import { LosseBlogBink } from '@ubo/losse-sjedel'
import type { ContentNode, Maybe, Page_Flexcontent_Flex_Posts, WpPageInfo } from '~/graphql/types'
import Post from './post-types/Post'

export default function PostsBlog({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  if (!fields.posts?.pageInfo) {
    return null
  }

  return (
    <section data-component="PostsBlog" className="section">
      <div className="container">
        <LosseBlogBink pageInfo={fields.posts?.pageInfo as Maybe<WpPageInfo> | undefined}>
          <Overview fields={fields} />
        </LosseBlogBink>
      </div>
    </section>
  )
}

type Edge<T = ContentNode> = {
  node: T & {
    __typename: string
  }
}

const getPostTypeName = (fields: Page_Flexcontent_Flex_Posts) => {
  const items = fields.posts?.edges as Edge[]
  return items?.[0]?.node?.__typename
}

const PostTypes: { [key: string]: any } = {
  Post: Post
}

function Overview({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const postTypeName = getPostTypeName(fields)

  const temp = fields?.posts?.edges?.concat(fields?.posts?.edges)?.concat(fields?.posts?.edges)

  return (
    <>
      <div className="grid grid-cols-3 gap-10">
        {temp?.map((edge) => {
          if (!edge || !edge?.node) return null

          const Component = PostTypes[postTypeName] || PostTypes.Post

          return (
            <div key={edge?.node?.uri} className="col-span-1">
              <Component data={edge?.node} />
            </div>
          )
        })}
      </div>
    </>
  )
}
