import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Button from '~/components/elements/Button'
import Circle from '~/components/elements/Circle'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'

export default function BannerHome({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  // Function that scrolls 1 window height down
  const scrollDown = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: 'smooth'
    })
  }

  return (
    <section data-component="BannerHome" className="section">
      <div className="container lg:min-h-[567px]">
        <div className="grid gap-8 lg:gap-0 grid-cols-1 lg:grid-cols-2">
          <div className="flex lg:pl-12 lg:pt-16 z-20 relative animate-fade-up animate-delay-100 animate-duration-500 animate-ease-in-out">
            <div onClick={() => scrollDown()} className="lg:block hidden absolute cursor-pointer right-0 bottom-12 smooth z-10">
              <div className="w-10 h-10 bg-site-alternative rounded-full" />
              <svg
                className="absolute animate-bounce duration-1000 top-8 left-0 right-0 mx-auto"
                width="24"
                height="51"
                viewBox="0 0 24 51"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.9393 50.0607C11.5251 50.6464 12.4749 50.6464 13.0607 50.0607L22.6066 40.5147C23.1924 39.9289 23.1924 38.9792 22.6066 38.3934C22.0208 37.8076 21.0711 37.8076 20.4853 38.3934L12 46.8787L3.51472 38.3934C2.92893 37.8076 1.97919 37.8076 1.3934 38.3934C0.807613 38.9792 0.807613 39.9289 1.3934 40.5147L10.9393 50.0607ZM10.5 6.55671e-08L10.5 49L13.5 49L13.5 -6.55671e-08L10.5 6.55671e-08Z"
                  fill="#F0831F"
                />
              </svg>
            </div>
            <div className="">
              <h1 className="title pb-3 lg:pb-6 ">{fields?.title}</h1>
              <Content className="content children-p:text-xl lg:pr-24">{fields?.description}</Content>
              {fields?.links && (
                <div className="flex flex-col gap-3 mt-5 lg:gap-6 lg:mt-10">
                  {fields.links.map((link, index) => (
                    <div key={index}>
                      <Button
                        className={clsx(index === 2 && 'btn--outline', index === 1 && 'btn--outline', 'btn')}
                        to={link?.link?.url || '/'}
                        target={link?.link?.target}
                      >
                        {link?.link?.title}
                      </Button>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="relative flex justify-center lg:justify-end animate-fade-up animate-duration-500 animate-ease-in-out">
            <Circle
              color="#F2DFCE"
              className="w-[400px] lg:w-[693px] absolute z-10 -top-[300px] lg:-top-[210px] -right-[200px] lg:-right-[250px]"
            />
            <Circle color="#F2DFCE" className="w-[273px] absolute z-10 -bottom-[50px] left-[20px]" />
            <LossePlaatjie
              loading="eager"
              maxWidth={2000}
              className="relative max-lg:max-h-[500px] max-sm:mx-auto max-sm:w-[80%] max-w-[450px] z-20 object-contain"
              src={fields?.image}
            />
          </div>
        </div>
      </div>
    </section>
  )
}
