import { FormDuufGroup, FormDuufSubmit } from '@ubo/form-duuf'
import clsx from 'clsx'
import { useRef, useState } from 'react'
import Content from '~/components/elements/Content'
import Form from '~/components/elements/Form'
import Loading from '~/components/elements/Loading'

import type { Page_Flexcontent_Flex_Form } from '~/graphql/types'
import { useInView } from 'framer-motion'

export default function FormApply({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  const [formStatus, setFormStatus] = useState('')

  const subRef = useRef(null)

  const subVisible = useInView(subRef, { once: true })

  return (
    <section data-component="FormApply" id="FormApply" className="section">
      <div className="container">
        <div
          ref={subRef}
          className={clsx(
            subVisible && 'animate-fade-up animate-delay-100 animate-duration-500 animate-ease-in-out',
            subVisible ? 'opacity-100' : 'opacity-0',
            'bg-site-secondary p-4 sm:p-7 md:p-16 rounded-3xl xl:w-4/5 mx-auto'
          )}
        >
          {formStatus !== 'done' && <h2 className="text-white font-bold text-2xl mb-8">{fields?.formtitle}</h2>}
          <Form
            className="form-apply"
            privacyUrl="/privacy-policy/"
            privacyUrlPlacement="Privacy"
            generate={false}
            scrollToConfirm={false}
            data={fields.form}
            renderLoading={() => <Loading />}
            renderStatus={({ status, content }) => {
              setFormStatus(status)

              return <Content className="content children-headings:text-white">{content}</Content>
            }}
          >
            <>
              <FormDuufGroup hideIds={[7]} />
              <div className="col-span-6 xl:col-span-2" />
              <div className="col-span-6 xl:col-span-4 flex flex-col sm:flex-row sm:justify-end">
                <FormDuufGroup showIds={[7]} />
                <div className="flex max-sm:justify-end">
                  <FormDuufSubmit />
                </div>
              </div>
            </>
          </Form>
        </div>
      </div>
    </section>
  )
}
