import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import FormContact from './FormContact'
import FormDownload from './FormDownload'
import FormApply from './FormApply'

export default function FormShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    contact: FormContact,
    download: FormDownload,
    apply: FormApply
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
