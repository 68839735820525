import { createContext, useState } from 'react'
import type { Dispatch, SetStateAction, PropsWithChildren } from 'react'
import { LosseLayout } from '@ubo/losse-sjedel'
import Footer from './Footer'
import Header from './Header/Header'
import FormSticky from './FormSticky'

export const CustomLayoutContext = createContext<{
  desktopMenuOpen: boolean
  setDesktopMenuOpen: Dispatch<SetStateAction<boolean>>
}>({
  desktopMenuOpen: false,
  setDesktopMenuOpen: (boolean) => boolean
})

export default function Layout({ children }: PropsWithChildren<{}>) {
  const [desktopMenuOpen, setDesktopMenuOpen] = useState(false)

  return (
    <LosseLayout>
      <CustomLayoutContext.Provider value={{ desktopMenuOpen, setDesktopMenuOpen }}>
        <Header />
        <main className="min-h-screen-dynamic pt-[70px] lg:pt-[140px]">{children}</main>
        <Footer />
        <FormSticky />
      </CustomLayoutContext.Provider>
    </LosseLayout>
  )
}
