import { LossePlaatjie, useLocation } from '@ubo/losse-sjedel'
import Author from '~/components/elements/Author'
import Breadcrumbs from '~/components/elements/Breadcrumbs'
import Content from '~/components/elements/Content'
import Share from '~/components/elements/Share'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'

export default function BannerDetail({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  const location = useLocation()

  return (
    <section data-component="BannerDetail" className="section">
      <div className="container">
        <div className="bg-site-base rounded-[12px] xs:rounded-[25px] animate-fade-up animate-duration-500 animate-ease-in-out sm:rounded-[50px] pt-6 pb-[180px] lg:pb-[200px] sm:pt-10 px-4 xs:px-6 sm:px-10 xl:px-14">
          <div className="grid grid-cols-1 gap-8 lg:gap-10 xl:gap-20">
            <div className="sm:pb-5 xl:pb-10">
              <div>
                <Breadcrumbs />
              </div>
              <div className="xs:px-0 sm:px-5 xl:px-10 pt-6 xl:pt-11">
                <h1 className="title-sm pb-5 !text-white">{fields?.title}</h1>
                <div className="flex lg:flex-row gap-4 flex-col justify-between lg:items-center">
                  <Author author={fields?.author} />
                  <Share url={`https://nederlandsenotariskluis.nl/${location?.pathname}`} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex relative z-10 justify-center items-center">
          {fields?.hasvideo ? (
            <iframe
              className="aspect-video w-[92%] lg:w-[calc(100%_-_192px)] animate-fade-up animate-delay-100 animate-duration-500 animate-ease-in-out mx-auto -mt-[150px] lg:-mt-[190px] rounded-[12px] lg:rounded-[50px]"
              src={fields?.video}
              title="Journey Through The Seven Continents Of Our World | 4K UHD | Seven Worlds One Planet | BBC Earth"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          ) : (
            <LossePlaatjie
              loading="eager"
              maxWidth={2000}
              className="w-[92%] lg:w-[calc(100%_-_192px)] animate-fade-up animate-delay-100 animate-duration-500 animate-ease-in-out mx-auto -mt-[150px] lg:-mt-[190px] rounded-[12px] lg:rounded-[50px]"
              src={fields?.image}
            />
          )}
        </div>
        <div className="max-w-screen-lg mx-auto pt-6 lg:pt-14">
          <Content className="content">{fields?.description}</Content>
        </div>
      </div>
    </section>
  )
}
