import { LosseBlogBink, type LosseBlogBinkReadableParams, LossePlaatjie, useLosseBlogBink, useSearchParams } from '@ubo/losse-sjedel'
import Breadcrumbs from '~/components/elements/Breadcrumbs'
import type { ContentNode, Maybe, Page_Flexcontent_Flex_Posts, WpPageInfo } from '~/graphql/types'
import Post from './post-types/Post'
import Faq from './post-types/Faq'
import clsx from 'clsx'
import Pagination from '~/components/elements/Pagination'

export const READABLE_PARAMS: LosseBlogBinkReadableParams = {
  filters: 'onderwerp'
}

export default function NewsOverview({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  return (
    <section data-component="NewsOverview" className="section">
      <div className="container sm:pb-20">
        <div className="relative">
          <LosseBlogBink pageInfo={fields.posts?.pageInfo as Maybe<WpPageInfo> | undefined} readableParams={READABLE_PARAMS}>
            <Overview fields={fields} />
          </LosseBlogBink>
        </div>
      </div>
    </section>
  )
}

type Edge<T = ContentNode> = {
  node: T & {
    __typename: string
  }
}

const getPostTypeName = (fields: Page_Flexcontent_Flex_Posts) => {
  const items = fields.posts?.edges as Edge[]
  return items?.[0]?.node?.__typename
}

const PostTypes: { [key: string]: any } = {
  Post: Post,
  VeelgesteldeVraag: Faq
}

function Overview({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const postTypeName = getPostTypeName(fields)
  const { hasNextPage, hasPrevPage, isFilterActive } = useLosseBlogBink()

  const [searchParams, setSearchParams] = useSearchParams()

  return (
    <div>
      <div className="bg-site-base rounded-[25px] animate-fade-up animate-duration-500 animate-ease-in-out sm:rounded-[50px] py-6 sm:py-10 px-4 xs:px-6 sm:px-10 xl:px-14">
        <div className="grid grid-cols-1 lg:grid-cols-5 lg:gap-10 xl:gap-10">
          <div className="sm:pb-5 xl:pb-[40px] col-span-3">
            <div>
              <Breadcrumbs />
            </div>
            <div className="flex flex-col justify-between sm:h-full sm:pl-5 xl:pl-10 pt-6 xl:pt-11">
              <h1 className="title lg:pb-5 !text-white">{fields?.title}</h1>
              <div className="bg-site-secondary max-sm:mt-4 w-fit rounded-[12px] lg:rounded-[25px] pt-3 sm:pt-6 lg:pt-11 px-6 sm:px-6 lg:px-9 pb-4 sm:pb-6 lg:pb-9">
                <div className="flex items-center gap-6 pb-2 sm:pb-5">
                  <div className="text-site-nav text-lg sm:text-2xl font-bold">{fields?.subtitle}</div>

                  <div
                    onClick={() => {
                      // Delete all searchParams
                      setSearchParams(new URLSearchParams(), {
                        state: {
                          scroll: false
                        }
                      })
                    }}
                    className="text-site-nav text-base cursor-pointer lg:text-xl font-light underline"
                  >
                    Reset filters
                  </div>
                </div>
                <div className="relative">
                  {fields?.filters
                    ?.filter((e) => e?.parentDatabaseId === null)
                    .map((filter, index) => {
                      return (
                        <div key={index} className="relative w-full lg:w-[unset]">
                          <svg
                            className="absolute pointer-events-none z-20 top-0 bottom-0 right-7 my-auto"
                            width="14"
                            height="9"
                            viewBox="0 0 14 9"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.7098 1.20998C13.6169 1.11625 13.5063 1.04186 13.3844 0.991091C13.2625 0.940323 13.1318 0.914185 12.9998 0.914185C12.8678 0.914185 12.7371 0.940323 12.6152 0.991091C12.4934 1.04186 12.3828 1.11625 12.2898 1.20998L7.70982 5.78997C7.61685 5.8837 7.50625 5.9581 7.38439 6.00886C7.26253 6.05963 7.13183 6.08577 6.99982 6.08577C6.86781 6.08577 6.7371 6.05963 6.61524 6.00886C6.49338 5.9581 6.38278 5.8837 6.28982 5.78997L1.70982 1.20998C1.61685 1.11625 1.50625 1.04186 1.38439 0.991091C1.26253 0.940323 1.13183 0.914185 0.999816 0.914185C0.867804 0.914185 0.737098 0.940323 0.615239 0.991091C0.49338 1.04186 0.382779 1.11625 0.289816 1.20998C0.103565 1.39734 -0.000976562 1.6508 -0.000976562 1.91498C-0.000976562 2.17917 0.103565 2.43262 0.289816 2.61998L4.87982 7.20997C5.44232 7.77177 6.20481 8.08733 6.99982 8.08733C7.79482 8.08733 8.55732 7.77177 9.11982 7.20997L13.7098 2.61998C13.8961 2.43262 14.0006 2.17917 14.0006 1.91498C14.0006 1.6508 13.8961 1.39734 13.7098 1.20998Z"
                              fill="#543575"
                            />
                          </svg>
                          <select
                            className="bg-white relative focus-within:outline-iw-acai border !appearance-none border-white rounded-full h-[35px] lg:h-[54px] pl-7 pr-8 w-full lg:min-w-[311px] text-iw-acai children-option:color-unset ring-iw-acai placeholder:text-iw-acai/50"
                            onChange={(e) => {
                              setSearchParams(new URLSearchParams({ [READABLE_PARAMS.filters]: e?.target.value }), {
                                state: {
                                  scroll: false
                                }
                              })
                            }}
                          >
                            <option className="bg-white text-site-nav" disabled selected value="">
                              {filter?.name}
                            </option>
                            {fields.filters
                              ?.filter((e) => e?.parentDatabaseId === filter?.databaseId)
                              .map((f, subIndex) => {
                                const isActive = isFilterActive(f?.name)
                                return (
                                  <option className="bg-white text-site-nav" key={subIndex} selected={isActive} value={f?.name}>
                                    {f?.name}
                                  </option>
                                )
                              })}
                          </select>
                        </div>
                      )
                    })}
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-end col-span-2 items-center">
            <LossePlaatjie
              maxWidth={320}
              className="max-lg:absolute w-fit max-lg:object-contain max-lg:h-[90%] max-lg:top-0 max-lg:my-auto max-lg:bottom-0 max-lg:right-5 max-lg:-z-10"
              src={fields.image}
            />
          </div>
        </div>
      </div>
      <div className="animate-fade-up animate-delay-200 animate-duration-500 animate-ease-in-out relative xl:w-[110%] xl:-left-[5%] z-20 mt-5 lg:mt-32">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-7 max-sm:pb-10 gap-y-10 lg:gap-y-20 gap-x-2 lg:gap-x-10">
          {fields.posts.edges?.map((edge, index) => {
            if (!edge || !edge.node) return null

            const Component = PostTypes[postTypeName] || PostTypes.Faq

            if (searchParams.get('search')) {
              // @ts-ignore
              if (!edge.node.recap.question.includes(searchParams.get('search'))) return null
            }

            return (
              <div
                key={edge.node.uri}
                className={clsx(
                  index === 0 && 'lg:col-span-3',
                  (index + 1) % 6 === 0 || (index + 1) % 7 === 0 ? 'lg:col-span-3' : 'lg:col-span-2'
                )}
              >
                <Component data={edge.node} />
              </div>
            )
          })}
        </div>
        {(hasNextPage || hasPrevPage) && (
          <div className="flex justify-center py-6 lg:py-10">
            <Pagination />
          </div>
        )}
      </div>
    </div>
  )
}
