import clsx from 'clsx'
import { useInView } from 'framer-motion'
import { useRef } from 'react'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'
import { PopupButton } from '@typeform/embed-react'

export default function ContentBlockContent({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const containerRef = useRef(null)

  const containerVisible = useInView(containerRef, { once: true })

  return (
    <section id={fields?.anchorId && fields?.anchorId} data-component="ContentBlockContent" className="section">
      <div
        ref={containerRef}
        className={clsx(
          containerVisible && 'animate-fade-up animate-delay-200 animate-duration-500 animate-ease-in-out',
          containerVisible ? 'opacity-100' : 'opacity-0',
          'container'
        )}
      >
        <div className="max-w-screen-lg mx-auto bg-site-alternative rounded-[25px] sm:rounded-[50px] p-6 sm:p-10 md:px-20 md:py-14">
          <h2 className="text-3xl pb-3 lg:pb-6 sm:text-4xl xl:text-5xl font-bold text-site-base">{fields?.title}</h2>
          <Content className="content">{fields?.description}</Content>
          {fields?.links && (
            <div className="flex flex-wrap items-center gap-2 mt-4">
              {fields.links?.map((link, index) => {

                if (link?.link?.title === 'Doe de test') {
                  return (
                    <PopupButton id="PkxqUa1f" key={index} className="btn">{link?.link?.title}</PopupButton>
                  )
                }

                return (
                  <Button
                    className={clsx(index === 2 && 'btn--alt', index === 1 && 'btn--outline', 'btn')}
                    key={index}
                    to={link?.link?.url || '/'}
                    target={link?.link?.target}
                  >
                    {link?.link?.title}
                  </Button>
                )
              }
              )}
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
