import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { useInView } from 'framer-motion'
import { useRef } from 'react'
import Circle from '~/components/elements/Circle'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentBigImage({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const containerRef = useRef(null)
  const imageBackgroundRef = useRef(null)

  const isVisible = useInView(containerRef, { once: true })
  const imageBackgroundIsVisible = useInView(imageBackgroundRef, { once: true })

  return (
    <section ref={containerRef} data-component="ContentBigImage" className="section">
      <div className="container max-sm:py-5">
        <h1
          className={clsx(
            isVisible && 'animate-fade-up animate-delay-150 animate-duration-500 animate-ease-in-out',
            isVisible ? 'opacity-100' : 'opacity-0',
            'text-site-nav relative z-20 text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl !leading-[1.25] font-bold sm:text-center sm:px-10'
          )}
        >
          {fields?.title}
        </h1>
        <Content
          className={clsx(
            isVisible && 'animate-fade-up animate-delay-200 animate-duration-500 animate-ease-in-out',
            isVisible ? 'opacity-100' : 'opacity-0',
            'content max-sm:children-p:!text-left max-w-screen-sm mx-auto pt-4 xs:pt-6'
          )}
        >
          {fields?.description}
        </Content>
        <div
          className={clsx(
            imageBackgroundIsVisible && 'animate-fade-up animate-delay-200 animate-duration-500 animate-ease-in-out',
            imageBackgroundIsVisible ? 'opacity-100' : 'opacity-0',
            'relative mt-4 xs:mt-8'
          )}
        >
          <div
            ref={imageBackgroundRef}
            className={clsx(
              imageBackgroundIsVisible && 'animate-fade-up animate-delay-300 animate-duration-500 animate-ease-in-out',
              imageBackgroundIsVisible ? 'opacity-100' : 'opacity-0',
              'bg-site-base absolute left-0 right-0 mx-auto z-10 -bottom-0 rounded-[12px] xs:rounded-[25px] sm:rounded-[50px] h-[80%] sm:h-[250px]  md:h-[300px] lg:h-[380px] xl:h-[510px]'
            )}
          >
            <Circle
              color="#B0CBD3"
              className="absolute w-[8%] sm:w-[80px] lg:w-[140px] bottom-4 sm:bottom-6 lg:bottom-10 right-4 sm:right-8 xl:right-12"
            />
            <Circle
              color="#B0CBD3"
              className="absolute w-[20%] sm:w-[200px] lg:w-[300px] xl:w-[365px] top-4 sm:top-8 lg:top-10 left-4 sm:left-8 lg:left-10 xl:left-20"
            />
          </div>
          <LossePlaatjie className="relative z-20" maxWidth={2000} src={fields?.image} />
        </div>
      </div>
    </section>
  )
}
