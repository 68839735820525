import { LosseFlex } from '@ubo/losse-sjedel'
import type { Page_Flexcontent } from '~/graphql/types'

// Flex components
import BannerShell from './Banner/BannerShell'
import ContentShell from './Content/ContentShell'
import Fragment from './Fragment'
import FormShell from './Form/FormShell'
import PostsShell from './Posts/PostsShell'
import SliderShell from './Slider/SliderShell'

interface FlexContentHandlerProps {
  flex: Page_Flexcontent['flex']
  prefix: string
}

export default function FlexContentHandler({ flex, prefix = 'page_Flexcontent' }: FlexContentHandlerProps) {
  const flexers = {
    [`${prefix}_Flex_Banner`]: BannerShell,
    [`${prefix}_Flex_Content`]: ContentShell,
    [`${prefix}_Flex_Posts`]: PostsShell,
    [`${prefix}_Flex_Form`]: FormShell,
    [`${prefix}_Flex_Slider`]: SliderShell,
    [`${prefix}_Flex_Fragment`]: Fragment
  }

  return <LosseFlex flex={flex as any} flexers={flexers as any} />
}
