import clsx from 'clsx'
import { useRef } from 'react'
import { EffectFade, Navigation } from 'swiper/modules'
import Circle from '~/components/elements/Circle'
import Content from '~/components/elements/Content'
import Slider, { Slide } from '~/components/elements/Slider'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'
import { useInView } from 'framer-motion'

export default function ContentQuote({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const containerRef = useRef(null)
  const titleRef = useRef(null)

  const containerVisible = useInView(containerRef, { once: true })
  const titleVisible = useInView(titleRef, { once: true })

  return (
    <section
      ref={containerRef}
      data-component="ContentQuote"
      className={clsx(
        containerVisible && 'animate-fade-up animate-delay-100 animate-duration-500 animate-ease-in-out',
        containerVisible ? 'opacity-100' : 'opacity-0',
        'section'
      )}
    >
      <div className="container py-6 sm:py-20">
        <div className="rounded-[25px] sm:rounded-[50px] bg-site-base relative overflow-hidden grid grid-cols-1 lg:grid-cols-7 gap-4 sm:gap-10 xl:gap-32">
          <Circle
            color="#B0CBD3"
            className="w-[110px] xs:w-[120px] absolute bottom-[unset] max-sm:top-5 max-lg:top-20 lg:-bottom-4 max-sm:-right-10 max-lg:-right-4 left-[unset] lg:left-[280px] xl:left-[400px]"
          />
          <div className="col-span-1 lg:col-span-3 pt-8 pl-[30px] sm:pl-[70px] lg:pb-[70px]">
            <div
              ref={titleRef}
              className={clsx(
                titleVisible && 'animate-fade-up animate-delay-300 animate-duration-500 animate-ease-in-out',
                titleVisible ? 'opacity-100' : 'opacity-0',
                'bg-site-secondary max-lg:w-[250px] aspect-square px-11 rounded-full relative flex justify-center items-center'
              )}
            >
              <svg
                className="absolute bottom-0 lg:bottom-10 xl:bottom-20 right-0 lg:right-6 xl:right-8 w-[60px] lg:w-[108px]"
                height="86"
                viewBox="0 0 108 86"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  id="&#226;&#128;&#156;"
                  d="M35.2448 40.2177C43.5524 43.7588 48.5874 51.3471 48.5874 61.7177C48.5874 76.3882 38.2657 86 24.4196 86C10.3217 86 0 76.1353 0 61.7177C0 55.1412 1.00699 50.3353 6.7972 36.4235L21.3986 0H46.3217L35.2448 40.2177ZM94.6573 40.2177C102.965 43.7588 108 51.3471 108 61.7177C108 76.3882 97.6783 86 83.8322 86C69.7343 86 59.4126 76.1353 59.4126 61.7177C59.4126 55.1412 60.4196 50.3353 66.2098 36.4235L80.8112 0H105.734L94.6573 40.2177Z"
                  fill="#171E39"
                />
              </svg>

              <h2 className="text-white font-bold text-3xl xl:text-5xl">{fields?.title}</h2>
            </div>
          </div>
          <div className="col-span-1 lg:col-span-4 pt-7 pb-7 sm:pb-12 lg:py-[55px] xl:py-[68px] flex flex-col justify-center max-sm:px-4 max-lg:px-20 lg:pr-[104px]">
            <Slider
              navigation={{
                prevEl: '#customprevquotebutton',
                nextEl: '#customnextquotebutton'
              }}
              modules={[Navigation, EffectFade]}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 0
                }
              }}
              effect="fade"
              fadeEffect={{
                crossFade: true
              }}
              className="h-full w-full"
            >
              {fields?.items?.map((item, index) => (
                <Slide className="h-auto" key={index}>
                  <div className="flex h-full flex-col justify-center">
                    <h3 className="text-lg pb-4 sm:pb-6 text-white">{item?.title}</h3>
                    <Content className="children-p:text-base sm:children-p:text-lg children-p:italic children-p:text-white children-p:font-bold">
                      {item?.description}
                    </Content>
                  </div>
                </Slide>
              ))}
            </Slider>
            <div className="h-10 mt-10 flex max-lg:justify-end">
              <div className="flex items-center">
                <svg
                  className="cursor-pointer"
                  id="customprevquotebutton"
                  width="42"
                  height="24"
                  viewBox="0 0 42 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    id="Arrow 2"
                    d="M0.939339 13.0607C0.353554 12.4749 0.353554 11.5251 0.939339 10.9393L10.4853 1.3934C11.0711 0.807611 12.0208 0.807611 12.6066 1.3934C13.1924 1.97919 13.1924 2.92893 12.6066 3.51472L4.12132 12L12.6066 20.4853C13.1924 21.0711 13.1924 22.0208 12.6066 22.6066C12.0208 23.1924 11.0711 23.1924 10.4853 22.6066L0.939339 13.0607ZM42 13.5H2V10.5H42V13.5Z"
                    fill="#F0831F"
                  />
                </svg>
                <div className="bg-white -ml-5 rounded-full w-10 h-10" />
                <svg
                  id="customnextquotebutton"
                  className="-ml-5 cursor-pointer"
                  width="42"
                  height="24"
                  viewBox="0 0 42 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    id="Arrow 1"
                    d="M41.0607 13.0607C41.6464 12.4749 41.6464 11.5251 41.0607 10.9393L31.5147 1.3934C30.9289 0.807611 29.9792 0.807611 29.3934 1.3934C28.8076 1.97919 28.8076 2.92893 29.3934 3.51472L37.8787 12L29.3934 20.4853C28.8076 21.0711 28.8076 22.0208 29.3934 22.6066C29.9792 23.1924 30.9289 23.1924 31.5147 22.6066L41.0607 13.0607ZM0 13.5H40V10.5H0V13.5Z"
                    fill="#F0831F"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
