import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { useRef } from 'react'
import Circle from '~/components/elements/Circle'
import Slider, { Slide } from '~/components/elements/Slider'
import type { Page_Flexcontent_Flex_Slider } from '~/graphql/types'
import { useInView } from 'framer-motion'
import { Autoplay } from 'swiper/modules'

export default function SliderOffices({ fields }: { fields: Page_Flexcontent_Flex_Slider }) {
  const titleRef = useRef(null)
  const sliderRef = useRef(null)

  const titleVisible = useInView(titleRef, { once: true })
  const sliderVisible = useInView(sliderRef, { once: true })

  return (
    <section data-component="SliderOffices" className="section">
      <div className="container py-6 sm:py-10 lg:py-20">
        <h2
          ref={titleRef}
          className={clsx(
            titleVisible && 'animate-fade-up animate-delay-100 animate-duration-500 animate-ease-in-out',
            titleVisible ? 'opacity-100' : 'opacity-0',
            'text-3xl sm:text-4xl xl:text-5xl font-bold !text-site-nav text-center'
          )}
        >
          {fields?.title}
        </h2>
        <div
          ref={sliderRef}
          className={clsx(
            sliderVisible && 'animate-fade-left animate-delay-300 animate-duration-500 animate-ease-in-out',
            sliderVisible ? 'opacity-100' : 'opacity-0'
          )}
        >
          <Slider
            modules={[Autoplay]}
            loop
            autoplay={{
              delay: 3000
            }}
            className="mt-6 sm:mt-12"
            breakpoints={{
              0: {
                slidesPerView: 2,
                spaceBetween: 10
              },
              576: {
                slidesPerView: 3,
                spaceBetween: 30
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 30
              }
            }}
          >
            {fields?.items?.map((item, index) => (
              <Slide className="group p-5 !h-auto !flex items-center" key={index}>
                <Circle color="#F2DFCE" className="absolute hidden group-hover:block smooth top-3 left-3 w-[50px]" />
                <div className="block">
                  <LosseLink className="relative w-full z-10" to={item?.link?.url} target={item?.link?.target}>
                    <LossePlaatjie className="w-full" maxWidth={500} src={item?.image} />
                  </LosseLink>
                </div>
              </Slide>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  )
}
