import { LosseLink, useLoaderData } from '@ubo/losse-sjedel'
import type { HeaderLoaderData } from './Header'
import clsx from 'clsx'
import { useCustomLayout } from '~/hooks/useCustomLayout'

export default function SubMenu() {
  const { header } = useLoaderData<HeaderLoaderData>()
  const { desktopMenuOpen } = useCustomLayout()

  return (
    <div className={clsx(desktopMenuOpen ? 'flex-col pt-5 md:pt-10' : 'flex-row py-5', 'gap-4 lg:gap-6 justify-end  flex')}>
      {header?.menuTopheader?.map((edge, index) => {
        return (
          <li className="relative" key={index}>
            <div className="flex flex-wrap items-center">
              <LosseLink
                className={clsx(
                  desktopMenuOpen && '!text-base lg:!text-base',
                  index === header.menuTopheader.length - 1 ? 'btn' : 'btn--alt'
                )}
                to={edge?.link?.url || '/'}
                target={edge.link.target}
              >
                {edge?.link?.title}
              </LosseLink>
            </div>
          </li>
        )
      })}
    </div>
  )
}
