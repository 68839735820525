import { LosseLink } from '@ubo/losse-sjedel'
import type { Page_Flexcontent_Flex_Posts, VeelgesteldeVraag } from '~/graphql/types'
import Faq from './post-types/Faq'
import clsx from 'clsx'
import { useRef } from 'react'
import { useInView } from 'framer-motion'
import Circle from '~/components/elements/Circle'

export default function FaqHighlighted({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const containerRef = useRef(null)

  const containerVisible = useInView(containerRef, { once: true })

  return (
    <section data-component="FaqHighlighted" className="section">
      <div className="container relative">
        <Circle color="#F2DFCE" className="absolute w-[200px] sm:w-[309px] -right-12 -bottom-16" />
        <div
          ref={containerRef}
          className={clsx(
            containerVisible && 'animate-fade-up animate-delay-100 animate-duration-500 animate-ease-in-out',
            containerVisible ? 'opacity-100' : 'opacity-0',
            'bg-site-secondary rounded-[25px] sm:rounded-[50px] p-5 sm:p-8 lg:pt-20 lg:pb-16 lg:px-14'
          )}
        >
          <div className="flex items-center gap-12">
            <h2 className="text-2xl lg:text-5xl text-white font-bold">{fields?.title}</h2>
            <SeeAllLink className="lg:flex hidden" fields={fields} />
          </div>
          <div className="flex flex-col lg:flex-row gap-x-20 pt-2 lg:pt-8">
            <div className="flex flex-col w-full lg:w-1/2">
              {fields?.highlighted.map((item, index) => {
                const faq = fields?.posts?.edges?.find((post) => post?.node?.databaseId === item?.databaseId)?.node as VeelgesteldeVraag

                // don't show even items
                if (index % 2 === 0) return null

                return (
                  <div key={index} className="col-span-1">
                    {index !== 1 && <div className="bg-white h-[2px] rounded-full w-full" />}
                    <Faq data={faq} />
                  </div>
                )
              })}
            </div>
            <div className="flex flex-col w-full lg:w-1/2">
              {fields?.highlighted?.map((item, index) => {
                const faq = fields?.posts?.edges?.find((post) => post?.node?.databaseId === item?.databaseId)?.node as VeelgesteldeVraag

                // don't show odd items
                if (index % 2 !== 0) return null

                return (
                  <div key={index} className="col-span-1">
                    <div className={clsx(index === 0 && 'lg:hidden', 'bg-white h-[2px] rounded-full w-full')} />
                    <Faq data={faq} />
                  </div>
                )
              })}
            </div>
          </div>
          <div className="lg:hidden flex justify-end">
            <SeeAllLink className="block lg:hidden" fields={fields} />
          </div>
        </div>
      </div>
    </section>
  )
}

const SeeAllLink = ({ className, fields }: { className: string; fields: Page_Flexcontent_Flex_Posts }) => {
  return (
    <LosseLink
      target={fields?.link?.target}
      className={clsx(className, 'flex group items-center gap-2 lg:gap-3 lg:text-base text-sm text-site-nav')}
      to={fields?.link?.url}
    >
      <div className="relative smooth z-10">
        <div className="w-5 lg:w-10 mr-5 h-5 lg:h-10 bg-site-alternative rounded-full" />
        <svg
          className="absolute w-[21px] lg:w-[42px] h-[12px] lg:h-[24px] smooth group-hover:left-5 lg:group-hover:left-7 top-0 bottom-0 my-auto left-3 lg:left-5"
          viewBox="0 0 42 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M41.0607 13.0607C41.6464 12.4749 41.6464 11.5251 41.0607 10.9393L31.5147 1.3934C30.9289 0.807611 29.9792 0.807611 29.3934 1.3934C28.8076 1.97919 28.8076 2.92893 29.3934 3.51472L37.8787 12L29.3934 20.4853C28.8076 21.0711 28.8076 22.0208 29.3934 22.6066C29.9792 23.1924 30.9289 23.1924 31.5147 22.6066L41.0607 13.0607ZM0 13.5H40V10.5H0V13.5Z"
            fill="#F0831F"
          />
        </svg>
      </div>
      <span className="group-hover:underline">{fields?.link?.title}</span>
    </LosseLink>
  )
}
