import { Widget } from "@typeform/embed-react";
import type { Page_Flexcontent_Flex_Content } from "~/graphql/types";

export default function ContentTypeForm({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentTypeForm" className="section">
      <div className="container">
        <Widget id={fields?.typeformId} className="w-full h-[400px] lg:h-[875px] mx-auto" />
      </div>
    </section>
  )
}
