import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import SliderOffices from './SliderOffices'

export default function SliderShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    offices: SliderOffices
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
