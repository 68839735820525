import clsx from 'clsx'
import { useRef } from 'react'
import Button from '~/components/elements/Button'
import Circle from '~/components/elements/Circle'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'
import { useInView } from 'framer-motion'

export default function ContentUspAlternative({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const textRef = useRef(null)
  const uspRef = useRef(null)

  const textVisible = useInView(textRef, { once: true })
  const uspVisible = useInView(uspRef, { once: true })

  return (
    <section data-component="ContentUspAlternative" className="section">
      <div className="container py-6 sm:py-16">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 sm:gap-14 lg:gap-20">
          <div
            ref={uspRef}
            className={clsx(
              uspVisible && 'animate-fade-up animate-delay-100 animate-duration-500 animate-ease-in-out',
              uspVisible ? 'opacity-100' : 'opacity-0',
              'relative'
            )}
          >
            <div className="flex flex-col gap-6 sm:gap-10">
              {fields?.items?.map((item, index) => (
                <div key={index} className="relative max-xs:pl-3 max-xs:items-start max-lg:flex max-lg:flex-col max-lg:items-center">
                  <h3 className="text-4xl xm:text-5xl max-lg:text-center xl:text-6xl relative z-10 !leading-[1.2] text-site-nav font-bold">
                    <Circle color="#F2DFCE" className="w-[50px] lg:w-[87px] -z-10 absolute -left-4 top-0 lg:-top-2 xl:top-0" />

                    {item?.title}
                  </h3>
                  <span className="pl-10 sm:pl-20 max-lg:text-center relative z-10 text-2xl sm:text-3xl xl:text-5xl !leading-[1.2] font-light text-site-base">
                    {item?.subtitle}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div
            ref={textRef}
            className={clsx(
              textVisible && 'animate-fade-up animate-delay-300 animate-duration-500 animate-ease-in-out',
              textVisible ? 'opacity-100' : 'opacity-0',
              'relative flex flex-col justify-center items-start sm:items-center'
            )}
          >
            <div className="bg-white max-lg:hidden rounded-full h-[550px] w-[550px] xl:h-[682px] xl:w-[682px] absolute top-0 bottom-0 m-auto" />
            <Content className="content max-sm:children-p:!text-left relative z-10 xl:px-10">{fields?.description}</Content>
            {fields?.links && (
              <div className="flex relative z-10 justify-start sm:justify-center items-center gap-2 mt-4 sm:mt-10">
                {fields?.links?.map((link, index) => (
                  <Button
                    className={clsx(index === 2 && 'btn--outline', index === 1 && 'btn--alt', 'btn')}
                    key={index}
                    to={link?.link?.url || '/'}
                    target={link?.link?.target}
                  >
                    {link?.link?.title}
                  </Button>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
